/* eslint-disable prefer-destructuring */
/* eslint-disable react/forbid-prop-types */
// import "bootstrap/dist/css/bootstrap.css";
import React, { Component } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { observer, inject } from "mobx-react";

@inject(stores => ({ projects: stores.account.projects }))
@observer
class Home extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    const collapse = this.state.collapse;
    this.setState({ collapse: !collapse });
  }

  render() {
    const intro1 = `${process.env.PUBLIC_URL}/img/intro_img/web_guide_text1.png`;
    const intro2 = `${process.env.PUBLIC_URL}/img/intro_img/web_guide_text2.png`;
    const intro3 = `${process.env.PUBLIC_URL}/img/intro_img/web_guide_text3.png`;
    const intro4 = `${process.env.PUBLIC_URL}/img/intro_img/web_guide_text4.png`;
    const intro5 = `${process.env.PUBLIC_URL}/img/intro_img/web_guide_text5.png`;
    return (
      <Container className="dashboard">
        <Row />
        <Row>
          <Col
            xl={{ size: 6, offset: 1 }}
            lg={{ size: 6, offset: 1 }}
            md={{ size: 6, offset: 1 }}
            sm={{ size: 6, offset: 1 }}
            xs={{ size: 6, offset: 1 }}
          >
            <Card>
              <CardBody>
                <div className="card__title">
                  <img src={intro1} alt="" />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            xl={{ size: 6, offset: 1 }}
            lg={{ size: 6, offset: 1 }}
            md={{ size: 6, offset: 1 }}
            sm={{ size: 6, offset: 1 }}
            xs={{ size: 6, offset: 1 }}
          >
            <Card>
              <CardBody>
                <div className="card__title">
                  <img src={intro2} alt="" />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            xl={{ size: 6, offset: 1 }}
            lg={{ size: 6, offset: 1 }}
            md={{ size: 6, offset: 1 }}
            sm={{ size: 6, offset: 1 }}
            xs={{ size: 6, offset: 1 }}
          >
            <Card>
              <CardBody>
                <div className="card__title">
                  <img src={intro3} alt="" />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            xl={{ size: 6, offset: 1 }}
            lg={{ size: 6, offset: 1 }}
            md={{ size: 6, offset: 1 }}
            sm={{ size: 6, offset: 1 }}
            xs={{ size: 6, offset: 1 }}
          >
            <Card>
              <CardBody>
                <div className="card__title">
                  <img src={intro4} alt="" />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            xl={{ size: 6, offset: 1 }}
            lg={{ size: 6, offset: 1 }}
            md={{ size: 6, offset: 1 }}
            sm={{ size: 6, offset: 1 }}
            xs={{ size: 6, offset: 1 }}
          >
            <Card>
              <CardBody>
                <div className="card__title">
                  <img src={intro5} alt="" />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Home;
