/* eslint-disable */
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
// import { Link, Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import axios from "axios";
import ProjectCard from "./ProjectCard";
import globals from "../../App/globals";

@inject(stores => ({
  projects: stores.account.projects,
  addProjectList: stores.account.addProjectList,
  addActionList: stores.account.addActionList,
  setInviteProjectCount: stores.account.setInviteProjectCount,
  token: stores.account.token,
  email: stores.account.email,
  setFileName: stores.account.setFileName
}))
@observer
class ProjectList extends Component {
  static propTypes = {
    projects: PropTypes.array.isRequired,
    addProjectList: PropTypes.func.isRequired,
    addActionList: PropTypes.func.isRequired,
    setInviteProjectCount: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    setFileName: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      projects: this.props.projects
    };
    this.handleCreate = this.handleCreate.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    axios
      // .get(`http://localhost:3001/users/projects`, {
      .get(`${globals.BASE_URL}/users/projects`, {
        headers: { "x-access-token": this.props.token }
      })
      .then(response => {
        if (response.data.status === "ok") {
          this.handleInviteProjectCount();
          if (response.data.projectList && response.data.projectList !== "") {
            this.props.addProjectList(response.data.projectList);
            this.setState({ projects: response.data.projectList });
            this.props.addActionList();
            this.props.setFileName("");
          }
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        // console.log(`error:  ${err}`);
        console.log(JSON.stringify(err));
      });
  }

  handleInviteProjectCount = () => {
    axios
      .get(`${globals.BASE_URL}/users/projectAll`, {
        headers: { "x-access-token": this.props.token }
      })
      .then(response => {
        if (response.data.status === "ok") {
          if (response.data.projectList && response.data.projectList !== "") {
            const projects = response.data.projectList;
            let count = 0;
            {
              projects && console.log("ininin");
              for (let i = 0; i < projects.length; i++) {
                if (projects[i].invite !== undefined && projects[i].invite !== "[]") {
                  if (projects[i].invite.indexOf(this.props.email) !== -1) {
                    count += 1;
                    this.props.setInviteProjectCount(count);
                  }
                }
              }
            }
          }
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  // handleRedirect = () => <Redirect to="/projectCreate" />;

  handleCreate = data => {
    const { projects } = this.state;
    this.setState({
      projects: projects.concat({ projectId: this.projectId + 1, ...data })
      // modal: false
    });
    this.props.addProject(data);
  };

  handleRemove = (projectId, pName) => {
    const { projects } = this.state;
    this.setState({
      projects: projects.filter(item => item.projectId !== projectId)
    });

    const projectDelete = `${globals.BASE_URL}/users/projects/${projectId}`;
    axios({
      method: "delete",
      url: projectDelete,
      data: {
        token: this.props.token,
        projectName: pName,
        ownerEmail: this.props.email
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          if (response.data.projectList && response.data.projectList !== "") {
            this.props.addProjectList(response.data.projectList);
            this.setState({ projects: response.data.projectList });
          }
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
    // this.props.removeProject(projectId);
  };

  handleUpdate = (projectId, data) => {
    const { projects } = this.state;
    this.setState({
      projects: projects.map(item => (projectId === item.projectId ? { ...item, ...data } : item))
    });
    const projectUpdate = `${globals.BASE_URL}/users/projects/${projectId}`;
    axios({
      method: "put",
      url: projectUpdate,
      data: {
        token: this.props.token,
        projectName: data.projectName,
        members: data.members,
        labels: data.labels
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          if (response.data.projectList && response.data.projectList !== "") {
            this.props.addProjectList(response.data.projectList);
            this.setState({ projects: response.data.projectList });
          }
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });

    // this.props.updateProject(projectId, data);
  };

  render() {
    return (
      <Col>
        <Row>
          <Col>
            <Button color="primary" style={{ float: "right" }} variant="outlined" onClick={this.dialogToggle}>
              <p>
                <Link to="/projectCreate">Create Project</Link>
              </p>
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <ProjectItemList items={this.state.projects} onRemove={this.handleRemove} onUpdate={this.handleUpdate} />
          </Col>
        </Row>
      </Col>
    );
  }
}
const ProjectItemList = ({ items, onRemove, onUpdate }) => {
  const itemList = items.map(item => <ProjectCard item={item} onRemove={onRemove} onUpdate={onUpdate} />);
  return <Row>{itemList}</Row>;
};

export default ProjectList;
