/* eslint-disable */
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import axios from "axios";
import { Card, CardBody, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button } from "@material-ui/core";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Delete from "@material-ui/icons/DeleteOutline";
import Info from "@material-ui/icons/Info";
// import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import globals from "../../../App/globals";

@inject(stores => ({
  token: stores.account.token,
  InProjectId: stores.account.InProjectId,
  projectName: stores.account.projectName
}))
@observer
class TrainingFileCard extends Component {
  static propTypes = {
    token: PropTypes.string.token,
    item: PropTypes.array.isRequired,
    InProjectId: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    projectName: PropTypes.string.projectName
  };

  constructor(props) {
    super(props);
    this.state = {
      modalTrainingFileDelete: false,
      infoOpen: false,
      download: this.props.item.download
    };
  }

  handleDownload = () => {
    // const download = `http://localhost:3001/users/projects/${this.props.InProjectId}/fileDownload`;
    const download = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/fileDownload`;
    axios({
      method: "post",
      url: download,
      responseType: "blob",
      data: {
        token: this.props.token,
        trainingName: this.props.item.trainingName,
        trainingId: this.props.item.trainingId
      }
    }).then(response => {
      this.setState({ download: this.props.item.download + 1 });
      // console.log(response.data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      // link.setAttribute("download", "csvFile.zip");
      link.setAttribute("download", "h5.zip");
      // link.setAttribute("download", this.props.item.trainingName + "_224.h5");
      document.body.appendChild(link);
      link.click();
    });
  };

  handleDelete = () => {
    this.setState({ modalTrainingFileDelete: true });
  };

  handleTrainingFileDelete = () => {
    this.setState({ modalTrainingFileDelete: false });

    this.props.onDelete(this.props.item.trainingId);

    const delete_training = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/delete_training`;
    axios({
      method: "delete",
      url: delete_training,
      data: {
        token: this.props.token,
        trainingName: this.props.item.trainingName,
        trainingId: this.props.item.trainingId,
        projectId: this.props.item.projectId
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log("ok");
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  };

  handleTrainingFileDeleteCancel = () => {
    this.setState({ modalTrainingFileDelete: false });
  };

  handleInfo = () => {
    this.setState({ infoOpen: !this.state.infoOpen });
  };

  render() {
    // console.log(this.props.item);
    return (
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <Row style={{ background: "#ED4C00", marginBottom: 10, marginRight: 0 }}>
          {/* <Row style={{ background: "#3A3A93", marginBottom: 10 }}> */}
          <Col xl={7} lg={7} md={7} sm={12} xs={12}>
            {/* <InsertDriveFile className="icon"/> */}
            <h5 style={{ paddingTop: 5, paddingLeft: 20 }}>{this.props.item.trainingName}</h5>
          </Col>
          <Col xl={5} lg={5} md={5} sm={12} xs={12} style={{ marginTop: 2 }}>
            <Info className="icon" onClick={this.handleInfo} />
            <SaveAlt className="icon" onClick={this.handleDownload} />
            <Delete className="icon" onClick={this.handleDelete} />
          </Col>
          <Modal isOpen={this.state.modalTrainingFileDelete} className={this.props.className}>
            <ModalHeader charCode="Y">Motion Training</ModalHeader>
            <ModalBody>{this.props.item.trainingName} delete ?</ModalBody>
            <ModalFooter>
              <Button color="primary" outline onClick={this.handleTrainingFileDelete}>
                ok
              </Button>{" "}
              <Button color="default" outline onClick={this.handleTrainingFileDeleteCancel}>
                cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
        <Dialog open={this.state.infoOpen} onClose={this.handleInfo} aria-labelledby="simple-dialog-title">
          <DialogTitle id="simple-dialog-title" style={{ textAlign: "center" }}>
            SDK Info
          </DialogTitle>
          <List>
            <ListItem>
              <ListItemText>Project Name : {this.props.projectName}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Trained Motion : {JSON.parse(this.props.item.labels).map(d => d.displayName)}</ListItemText>
            </ListItem>
            {/* <ListItem>
              <ListItemText>Algorithm accuracy : abcdefghijklmn</ListItemText>
            </ListItem> */}
            <ListItem>
              <ListItemText>Downloads : {this.state.download}</ListItemText>
            </ListItem>
          </List>
        </Dialog>
      </Col>
    );
  }
}

export default TrainingFileCard;
