/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
// import { Button, Chip, Avatar } from "@material-ui/core";
import { Card, CardContent, Button, Typography } from "@material-ui/core";
// import { Col, Row, Input, Modal, ModalHeader, ModalBody, ModalFooter, ButtonToolbar } from "reactstrap";
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, ButtonToolbar } from "reactstrap";
import PersonIcon from "@material-ui/icons/Person";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from "mdi-react/AddCircleIcon";
import Clear from "@material-ui/icons/Clear";

@observer
class FormTwo extends Component {
  static propTypes = {
    prevStep: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    membersString: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      memberEmail: "",
      // memberName: "",
      memberList: this.props.membersString
    };
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  memberAdd = () => {
    const mL = this.state.memberList;
    mL.push(this.state.memberEmail);
    // mL.push({ email: this.state.memberEmail, name: this.state.memberName });
    this.setState(() => ({
      memberList: mL,
      modal: !this.state.modal,
      memberEmail: ""
      // memberName: ""
    }));
  };

  nextStep = () => {
    const { memberList } = this.state;
    this.props.handleChange("members", memberList, 2);
  };

  prevStep = () => {
    this.props.prevStep("members", this.state.memberList, 2);
  };

  handleInputChange = e => {
    const val = e.target.value;
    this.setState({
      [e.target.name]: val
    });
  };

  handleMemberDelete = item => {
    this.setState({
      memberList: this.state.memberList.filter(d => d.email !== item)
    });
  };

  render() {
    return (
      <form className="form form--horizontal row__form">
        <Col>
          <Row className="form form__form-group">
            <h3 className="wizard__title">함께 작업할 멤버를 추가해 주세요</h3>
          </Row>
          <Row>
            <Col xl={11} lg={11} md={11} sm={11} xs={11}>
              {this.state.memberList && <MemberList items={this.state.memberList} onDelete={this.handleMemberDelete} />}
            </Col>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
              {/* --------------- plus button ---------------- */}
              <AddCircleIcon color="#643ed9" onClick={this.toggle} />
              <Modal isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader
                  style={{
                    borderRadius: "3px 3px 0 0",
                    backgroundColor: "#af82da",
                    color: "white"
                  }}
                >
                  <h4 style={{ paddingLeft: "175px" }}>Member Add</h4>
                </ModalHeader>
                <ModalBody style={{ paddingLeft: 170 }}>
                  <Row>
                    {/* <TextField
                      // id="outlined-with-placeholder"
                      label="Member Name"
                      margin="normal"
                      variant="outlined"
                      name="memberName"
                      type="text"
                      value={this.state.memberName}
                      onChange={this.handleInputChange}
                    /> */}
                    {/* <Col md={1} /> */}
                    <TextField
                      id="outlined-with-placeholder"
                      label="Member Email"
                      margin="normal"
                      variant="outlined"
                      name="memberEmail"
                      type="text"
                      value={this.state.memberEmail}
                      onChange={this.handleInputChange}
                    />
                  </Row>
                </ModalBody>
                <ModalFooter style={{ paddingBottom: "0px" }}>
                  <Button color="purple" outline size="sm" onClick={this.memberAdd}>
                    Add
                  </Button>{" "}
                  <Button color="purple" outline size="sm" onClick={this.toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <ButtonToolbar className="form__button-toolbar wizard__toolbar">
              <Button color="primary" style={{ size: 25, marginRight: 23 }} variant="outlined" onClick={this.prevStep}>
                Prev
              </Button>{" "}
              <Button color="primary" style={{ size: 25 }} variant="outlined" onClick={this.nextStep}>
                Next
              </Button>{" "}
            </ButtonToolbar>
          </Row>
        </Col>
      </form>
    );
  }
}

class MemberList extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired
  };

  handleMemberDelete = item => {
    this.props.onDelete(item);
  };

  render() {
    const itemList = this.props.items.map(item => (
      <Card
        style={{
          margin: 7,
          backgroundColor: "#643ed9",
          opacity: 0.7
        }}
      >
        <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Row>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
              <PersonIcon style={{ marginTop: 5 }} />
              {/* <PersonIcon style={{ marginTop: 14 }} /> */}
            </Col>
            <Col style={{ marginLeft: 10, paddingRight: 0, marginTop: 5 }}>
              {/* <Typography variant="h6" style={{ color: "#fff" }}>
                {item.name}
              </Typography> */}
              <Typography color="textSecondary" gutterBottom style={{ color: "#fff" }}>
                {item}
              </Typography>
            </Col>
            <Clear style={{ paddingTop: 4, marginTop: 2 }} onClick={() => this.handleMemberDelete(item.email)} />
          </Row>
        </CardContent>
      </Card>
    ));
    return <Row>{itemList}</Row>;
  }
}
export default FormTwo;
