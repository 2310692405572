/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-expressions */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-console */
import React, { Component } from "react";
import { Col, Card, Row } from "reactstrap";
import { observer, inject } from "mobx-react";
import Snackbar from "@material-ui/core/Snackbar";
import PropTypes from "prop-types";
import axios from "axios";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import FormThree from "./FormThree";
import globals from "../../App/globals";

@inject(stores => ({
  token: stores.account.token
}))
@observer
class Form extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      projectName: "",
      members: [],
      labels: [],
      vertical: "top",
      horizontal: "center",
      errorMsg: ""
    };
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
  }

  handleChange = (key, value, step) => {
    console.log(key, value, step);
    this.setState({ [key]: value }, () => {
      this.nextStep(step);
    });
  };

  handleSnackbarClose = () => {
    this.setState({ openErrorMsg: false });
  };

  handleErrorMsg = msg => {
    this.setState({ openErrorMsg: true, errorMsg: msg });
  };

  nextStep = step => {
    switch (step) {
      case 1:
        if (this.state.projectName === undefined || this.state.projectName === "") {
          this.handleErrorMsg("프로젝트명이 없습니다. 프로젝트명을 입력해 주세요");
          return;
        }
        break;
      case 2:
        break;
      case 3:
        const { members } = this.state;

        for (let i = 0; i < members.length; i++) {
          this.handleMemberCheck(members[i]);
        }

        // 초대메일 보내는 시간을 벌기 위해 1.5초 delay
        setTimeout(() => {
          this.handleProjectInsert();
        }, 1500);

        break;
      default:
        break;
    }
    this.setState({
      step: step + 1
    });
  };

  prevStep = (key, item, step) => {
    this.setState({
      step: step - 1,
      [key]: item
    });
  };

  handleProjectInsert = () => {
    const { members, labels, projectName } = this.state;

    const pName = projectName.replace(/ /g, "");
    const m = JSON.stringify(members);
    const l = JSON.stringify(labels);

    // const projects = `http://localhost:3001/users/projects`;
    const projects = `${globals.BASE_URL}/users/projects`;
    axios({
      method: "post",
      url: projects,
      data: {
        token: this.props.token,
        projectName: pName,
        invite: m,
        labels: l
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log(`ok`);
          window.location.replace("/");
        } else {
          console.log(`error : ${response.data.msg}`);
          this.handleErrorMsg("같은 프로젝트명이 있습니다. 다른 이름을 선택해 주세요.");
          this.setState({
            step: 3
          });
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  };

  handleMemberCheck = memberEmail => {
    // 회원인가 체크
    const emailCheck = `${globals.BASE_URL}/users/post_email_check`;
    axios({
      method: "post",
      url: emailCheck,
      data: {
        token: this.props.token,
        email: memberEmail
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log("회원가입해야함.");
          // 초대 되었다고 가입하라는 메일이 가야함.
          this.handleSignInMail(memberEmail);
        } else {
          // 초대
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  };

  handleSignInMail = memberEmail => {
    const inviteSignupMail = `${globals.BASE_URL}/users/invite_signup_mail`;
    axios({
      method: "post",
      url: inviteSignupMail,
      data: {
        token: this.props.token,
        email: memberEmail
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log("mail _ ok");
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  };

  render() {
    const { step, vertical, horizontal, openErrorMsg, projectName } = this.state;

    return (
      <Row>
        <Col>
          <Card>
            <div className="wizard">
              <div className="wizard__steps">
                <div className={`wizard__step${step === 1 ? " wizard__step--active" : ""}`}>
                  <p>Step 1</p>
                </div>
                <div className={`wizard__step${step === 2 ? " wizard__step--active" : ""}`}>
                  <p>Step 2</p>
                </div>
                <div className={`wizard__step${step === 3 ? " wizard__step--active" : ""}`}>
                  <p>Step 3</p>
                </div>
              </div>
              <div className="wizard__form-wrapper">
                {step === 1 && <FormOne handleChange={this.handleChange} projectName={projectName} />}
                {step === 2 && (
                  <FormTwo
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    membersString={this.state.members}
                  />
                )}
                {step === 3 && (
                  <FormThree
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    labelsString={this.state.labels}
                  />
                )}
              </div>
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openErrorMsg}
                onClose={this.handleSnackbarClose}
                autoHideDuration={2000}
                ContentProps={{
                  "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{this.state.errorMsg}</span>}
              />
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form;
