/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
/* eslint-disable */
import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Popover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import { Chip, Avatar, Button } from "@material-ui/core";
import { Label, Create, DeleteOutline, PeopleOutline, AccountBox, Done, Cancel } from "@material-ui/icons";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

@inject(stores => ({
  setProjectId: stores.account.setProjectId,
  setProjectName: stores.account.setProjectName,
  setOwnerEmail: stores.account.setOwnerEmail,
  email: stores.account.email
}))
@observer
class ProjectCard extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    setProjectId: PropTypes.func.isRequired,
    setOwnerEmail: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      memberOpen: false,
      editing: false,
      projectId: "",
      projectName: "",
      oldProjectName: "",
      members: [],
      // selectedMember: "",
      labels: "",
      modalDelete: false
    };
    this.handleMemberShow = this.handleMemberShow.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
    this.handleEditProjectName = this.handleEditProjectName.bind(this);
    this.handleEditProjectNameConfirm = this.handleEditProjectNameConfirm.bind(this);
    this.handleEditProjectNameCancel = this.handleEditProjectNameCancel.bind(this);
    this.handleProjectView = this.handleProjectView.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const tempProjectName = this.props.item.projectName;
    this.setState({
      projectId: this.props.item.projectId,
      projectName: this.props.item.projectName,
      oldProjectName: tempProjectName,
      members: this.props.item.members,
      // selectedMember: {},
      labels: this.props.item.labels
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { item, onUpdate } = this.props;
    if (!prevState.editing && this.state.editing) {
      this.setState({
        projectId: item.projectId,
        projectName: item.projectName,
        members: item.members,
        labels: item.labels
      });
    }

    if (prevState.editing && !this.state.editing) {
      // editing 값이 true -> false 로 전환 될 때
      onUpdate(item.projectId, {
        projectId: this.state.projectId,
        projectName: this.state.projectName,
        members: this.state.members,
        labels: this.state.labels
      });
    }
  }

  convertTime = timeStamp => moment(timeStamp * 1000).format("L LTS");

  // 멤버 보기
  handleMemberShow = () => {
    const { item } = this.props;
    if (JSON.parse(item.members).map(d => d.name).length !== 0) {
      this.setState({
        memberOpen: !this.state.memberOpen
      });
    }
  };

  handleMemberClose = () => {
    this.setState({
      memberOpen: false
    });
  };

  // 삭제 버튼
  handleRemove = () => {
    this.setState({ modalDelete: true });
  };

  // 삭제 확인
  handleDeleteConfirm = () => {
    this.setState({ modalDelete: false });
    const { item, onRemove } = this.props;
    onRemove(item.projectId, item.projectName);
  };

  // 삭제 취소
  handleDeleteCancel = () => {
    this.setState({ modalDelete: false });
  };

  // 수정 버튼
  handleEditProjectName = () => {
    this.setState({ editing: !this.state.editing });
  };

  // 수정 하기
  handleEditProjectNameConfirm = () => {
    // 이름 가져 와서 바꾸기
    const { item, onUpdate } = this.props;
    const pName = this.state.projectName.replace(/ /g, "");
    item.projectName = pName;
    this.setState({ editing: !this.state.editing, projectName: pName });
    onUpdate(item.projectId, item);
  };

  // 수정 취소
  handleEditProjectNameCancel = () => {
    console.log("this.state.oldProjectName : ", this.state.oldProjectName);
    this.setState({
      editing: !this.state.editing,
      projectName: this.state.oldProjectName
    });
  };

  // 개별 프로젝트 이동
  handleProjectView = () => {
    const { item } = this.props;
    this.props.setProjectId(item.projectId);
    this.props.setOwnerEmail(item.ownerEmail);
  };

  // 입력값 변경
  handleInputChange = e => {
    const val = e.target.value;
    this.setState({
      [e.target.name]: val
    });
  };

  render() {
    const { item } = this.props;
    const { editing } = this.state;
    const labels = item.labels && JSON.parse(item.labels);
    const memberCount = (item.members && JSON.parse(item.members).map(d => d.name).length) || 0;
    const membersArr = (item.members && JSON.parse(item.members)) || [];

    if (editing) {
      // 수정 모드
      return (
        <Col md={12} xl={4} key={item.projectId}>
          <Card>
            <CardBody>
              <ul className="sidebar__block">
                <Row>
                  <Col>
                    <Input
                      value={this.state.projectName}
                      name="projectName"
                      placeholder="이름"
                      onChange={this.handleInputChange}
                      className="project-card__input"
                    />
                  </Col>
                  <Col md={1} sm={1} xs={1} className="project-card__col-center">
                    <Done onClick={this.handleEditProjectNameConfirm} className="project-card__material-icon" />
                  </Col>
                  <Col md={1} sm={1} xs={1} className="project-card__col-center">
                    <Cancel onClick={this.handleEditProjectNameCancel} className="project-card__material-icon" />
                  </Col>
                </Row>
              </ul>
              <ul />
              {/*  한줄 띄기 */}
              <Row className="project-card__row-height-blank" />
              {/* label */}
              <Row>
                <Col className="project-card__margin-auto">
                  {labels && labels.map(label => <Chip label={label.display} className="project-card__chip-margin" />)}
                </Col>
              </Row>
              <Row className="project-card__row-height-blank" />
              <Row className="project-card__row-height-blank" />
              {/* 방장 / 인원수    방 만든 시간 */}
              <Row style={{ position: "absolute", bottom: 45, width: "100%" }}>
                {/* <Row className="project-card__row_bottom"> */}
                {/* 방장 */}
                <Col md={1} sm={1} xs={1} className="project-card__margin-auto">
                  <AccountBox className="project-card__material-icon" />
                </Col>
                <Col md={2} sm={2} xs={2} className="project-card__margin-auto">
                  <h4 className="subhead">{item.ownerName}</h4>
                </Col>
                {/* 인원수 */}
                <Col md={4} sm={4} xs={4} className="project-card__margin-auto">
                  <PeopleOutline className="project-card__material-icon-center" />
                  <Button
                    color="primary"
                    className="project-card__material-icon-center"
                    id={item.projectName}
                    onClick={this.handleMemberShow}
                  >
                    <h4 className="subhead">{`${memberCount}명`}</h4>
                  </Button>
                  <Col md={2} sm={2} xs={2}>
                    <Popover
                      placement="bottom"
                      isOpen={this.state.memberOpen}
                      target={item.projectName}
                      toggle={this.handleMemberShow}
                    >
                      <PopoverBody>
                        {membersArr.map(member => (
                          <ListItem button onClick={() => this.handleListItemClick(member)} key={member.email}>
                            <ListItemAvatar>
                              <Avatar>
                                <PersonIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={member.name} />
                          </ListItem>
                        ))}
                      </PopoverBody>
                    </Popover>
                  </Col>
                </Col>
                {/* 프로젝트 만든 시간 */}
                <Col className="project-card__margin-auto">
                  <h4 className="subhead">{this.convertTime(item.dateTime)}</h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      );
    }
    // 일반 모드
    return (
      <Col md={12} xl={4} key={item.projectId}>
        <Card>
          <CardBody>
            <ul className="sidebar__block">
              <Row>
                <Col>
                  <Link to={`/projects/${item.projectId}`} onClick={this.handleProjectView}>
                    <Row>
                      <p style={{ margin: 4, marginLeft: 13, marginRight: 10 }}>▶</p>
                      <h4 className="bold-text">{item.projectName}</h4>
                    </Row>
                  </Link>
                </Col>
                {item.ownerEmail === this.props.email ? (
                  <>
                    <Col className="project-card__col-center" sm={1} xs={1}>
                      <Create onClick={this.handleEditProjectName} className="project-card__material-icon" />
                    </Col>
                    <Col className="project-card__col-center" sm={1} xs={1}>
                      <DeleteOutline onClick={this.handleRemove} className="project-card__material-icon" />
                      <Modal
                        isOpen={this.state.modalDelete}
                        toggle={this.toggleDelete}
                        className={this.props.className}
                      >
                        <ModalHeader toggle={this.toggleDelete} charCode="Y">
                          프로젝트 삭제
                        </ModalHeader>
                        <ModalBody>
                          {`${item.projectName} `} 를 정말 삭제 하시겠습니까?
                          <br />
                          입력된 기존 모션 데이터도 삭제 됩니다.
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" outline onClick={this.handleDeleteConfirm}>
                            확인
                          </Button>{" "}
                          <Button color="default" outline onClick={this.handleDeleteCancel}>
                            취소
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Col>
                  </>
                ) : (
                  <p />
                )}
              </Row>
            </ul>
            {/*  한줄 띄기 */}
            <Row className="project-card__row-height-blank" />
            {/* label */}
            <Row>
              <Col className="project-card__margin-auto">
                {labels && labels.map(label => <Chip label={label.display} className="project-card__chip-margin" />)}
              </Col>
            </Row>
            <Row className="project-card__row-height-blank" />
            <Row className="project-card__row-height-blank" />
            {/* 방장 / 인원수    방 만든 시간 */}
            <Row style={{ position: "absolute", bottom: 45, width: "100%" }}>
              {/* <Row className="project-card__row_bottom"> */}
              {/* 방장 */}
              <Col md={1} sm={1} xs={1} className="project-card__margin-auto">
                <AccountBox className="project-card__material-icon" />
              </Col>
              <Col md={2} sm={2} xs={2} className="project-card__margin-auto">
                <h4 className="subhead">{item.ownerName}</h4>
              </Col>
              {/* 인원수 */}
              <Col md={4} sm={4} xs={4} className="project-card__margin-auto">
                <PeopleOutline className="project-card__material-icon-center" />
                <Button
                  color="primary"
                  className="project-card__material-icon-center"
                  id={item.projectName}
                  onClick={this.handleMemberShow}
                >
                  <h4 className="subhead">{`${memberCount}명`}</h4>
                </Button>
                <Col md={2} sm={2} xs={2}>
                  <Popover
                    placement="bottom"
                    isOpen={this.state.memberOpen}
                    target={item.projectName}
                    toggle={this.handleMemberShow}
                  >
                    <PopoverBody>
                      {membersArr.map(member => (
                        <ListItem button onClick={() => this.handleListItemClick(member)} key={member.email}>
                          <ListItemAvatar>
                            <Avatar>
                              <PersonIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={member.name} />
                        </ListItem>
                      ))}
                    </PopoverBody>
                  </Popover>
                </Col>
              </Col>
              {/* 프로젝트 만든 시간 */}
              <Col className="project-card__margin-auto">
                <h4 className="subhead">{this.convertTime(item.dateTime)}</h4>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default ProjectCard;
