/* eslint-disable */
import React, { Component } from "react";
import { Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ActionTable from "./components/ActionTable/ActionTable";
import TestImage from "./components/TestImage/TestImage";
import MemberDialog from "./components/MemberDialog";
import blue from "@material-ui/core/colors/blue";
import TrainingFileList from "./components/TrainingFile/TrainingFileList";
import globals from "../App/globals";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  }
};

const Dialog = withStyles(styles)(MemberDialog);

// Action index
@inject(stores => ({
  isLogin: stores.account.isLogin,
  projects: stores.account.projects,
  InProjectId: stores.account.InProjectId,
  token: stores.account.token,
  email: stores.account.email,
  ownerEmail: stores.account.ownerEmail,
  setProjectName: stores.account.setProjectName
}))
@observer
class Actions extends Component {
  static propTypes = {
    isLogin: PropTypes.bool.isRequired,
    projects: PropTypes.array.isRequired,
    InProjectId: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    ownerEmail: PropTypes.string.isRequired,
    setProjectName: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      projectName: "",
      open: false,
      members: [],
      modalDelete: false
    };
  }

  componentDidMount() {
    const memberSave = this.props.projects.filter(d => d.projectId === this.props.InProjectId).map(d => d.members)[0];
    const projectNameSave = this.props.projects
      .filter(d => d.projectId === this.props.InProjectId)
      .map(d => d.projectName);
    this.setState(() => ({
      projectName: projectNameSave,
      members: JSON.parse(memberSave)
    }));
    this.props.setProjectName(projectNameSave);
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
    console.log("this.state.open", this.state.open);
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  handleMemberAdd = members => {
    this.setState({
      members: members
    });
  };

  // 삭제 버튼
  handleRemove = () => {
    this.setState({ modalDelete: true });
  };

  // 삭제 취소
  handleDeleteCancel = () => {
    this.setState({ modalDelete: false });
  };

  // 삭제 확인
  handleDeleteConfirm = () => {
    this.setState({ modalDelete: false });

    const projectFilter = this.props.projects.filter(d => d.projectId === this.props.InProjectId).map(d => d.members);
    const project = JSON.parse(projectFilter);

    const removeMember = JSON.stringify(project.filter(d => d.email !== this.props.email));

    const memberDelete = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/members`;
    axios({
      method: "put",
      url: memberDelete,
      data: {
        token: this.props.token,
        members: removeMember
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log("ok");
          window.location.replace("/");
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  };

  handleMd5CSVDownload = () => {
    // const md5CsvDownload = `http://localhost:3001/users/projects/${this.props.InProjectId}/md5CsvDownload`;
    const md5CsvDownload = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/md5CsvDownload`;
    axios({
      method: "post",
      url: md5CsvDownload,
      data: {
        token: this.props.token
      }
    }).then(response => {});
  };

  render() {
    const { isLogin } = this.props;
    if (!isLogin) return <Redirect to="/login" />;
    return (
      <Container className="dashboard">
        <Col>
          <div className="card__title">
            <Row>
              <Col>
                <Row>
                  <p style={{ margin: 13 }}>▶</p>
                  <h1 className="bold-text">{this.state.projectName}</h1>
                </Row>
              </Col>
              <Col>
                {!(this.props.ownerEmail === this.props.email) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ float: "right", marginLeft: 10 }}
                    onClick={this.handleRemove}
                  >
                    나가기
                  </Button>
                )}
                <Modal isOpen={this.state.modalDelete} toggle={this.toggleDelete}>
                  <ModalHeader toggle={this.toggleDelete} charCode="Y">
                    나가기
                  </ModalHeader>
                  <ModalBody>프로젝트에서 나가시겠습니까?</ModalBody>
                  <ModalFooter>
                    <Button color="primary" outline onClick={this.handleDeleteConfirm}>
                      확인
                    </Button>
                    <Button color="secondary" outline onClick={this.handleDeleteCancel}>
                      취소
                    </Button>
                  </ModalFooter>
                </Modal>
                <Button
                  // color="primary"
                  style={{
                    float: "right",
                    color: "#fff",
                    backgroundColor: "#5E4E9C",
                    paddingLeft: 10,
                    paddingRight: 10
                  }}
                  onClick={this.handleClickOpen}
                >
                  MEMBER +
                </Button>
                <Button
                  // color="primary"
                  style={{
                    float: "right",
                    color: "#fff",
                    backgroundColor: "#5E4E9C",
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginRight: 10
                  }}
                  onClick={this.handleMd5CSVDownload}
                >
                  CSV
                </Button>
                {/* <Button variant="outlined" color="primary" style={{ float: "right" }} onClick={this.handleClickOpen}>
                  MEMBER
                </Button> */}
                <Dialog
                  // selectedValue={this.state.selectedValue}
                  InProjectId={this.props.InProjectId}
                  open={this.state.open}
                  onClose={this.handleClose}
                  members={this.state.members}
                  onMemberAdd={this.handleMemberAdd}
                />
              </Col>
            </Row>
          </div>
          <div className="tabs tabs--bordered-bottom">
            <Row>
              <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                <ActionTable />
                {/* <ActionTable handleMotionTestSelected={this.handleMotionTestSelected} /> */}
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <TestImage />
                {/* <TestImage actions={this.state.actions} selected={this.state.selected} /> */}

                {/* <Row>
                  <TrainingFileList updateSDK={this.props.updateSDK} onUpdate={this.handleSDKUpdate} />
                </Row> */}
              </Col>
            </Row>
          </div>
        </Col>
      </Container>
    );
  }
}
export default Actions;
