import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import SignInForm from "./components/SignInForm";

const SignIn = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          {/* <h4 className="account__subhead subhead">인공지능 모션 인식 플랫폼</h4> */}
          <h4 className="account__subhead subhead">Artificial intelligence motion recognition platform</h4>
          <h3 className="account__title">
            <span className="account__logo-accent"> My Motion</span>
            <span className="account__logo"> Sign Up</span>
            {/* <span className="account__logo"> 회원 가입 하기</span> */}
          </h3>
        </div>
        <SignInForm />
        <div className="account__or">
          <p>OR</p>
          {/* <p>또는</p> */}
        </div>
        <div className="account__social">
          {/* <Link className="btn btn-primary account__btn account__btn--small" to="/login">
            LogIn
          </Link> */}
          <Button
            color="primary"
            className="btn btn-primary account__btn account__btn--small"
            style={{ width: "100%", height: 40, marginTop: 20, size: 30 }}
            variant="outlined"
          >
            <Link to="/login" style={{ color: "#fff" }}>
              LogIn
            </Link>
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default SignIn;
