/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React, { Component } from "react";
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, ButtonToolbar } from "reactstrap";
import { Card, CardContent, Button, Typography } from "@material-ui/core";
// import { Card, CardContent, Button, Typography, Avatar } from "@material-ui/core";
import PropTypes from "prop-types";
import AddCircleIcon from "mdi-react/AddCircleIcon";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Clear from "@material-ui/icons/Clear";

class FormThree extends Component {
  static propTypes = {
    prevStep: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    labelsString: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      display: "",
      labelList: this.props.labelsString,
      errorMsg: "",
      vertical: "top",
      horizontal: "center",
      count: 1
    };
    this.finishStep = this.finishStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.labelAdd = this.labelAdd.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  labelAdd = () => {
    const lb = this.state.labelList;
    lb.push({
      display: this.state.display,
      label: JSON.stringify(this.state.count),
      group: ""
    });

    this.setState(() => ({
      labelList: lb,
      modal: !this.state.modal,
      display: "",
      count: this.state.count + 1
    }));
  };

  handleSnackbarClose = () => {
    this.setState({ openErrorMsg: false });
  };

  handleErrorMsg = msg => {
    this.setState({ openErrorMsg: true, errorMsg: msg });
  };

  finishStep = () => {
    console.log("this.state.labelList", this.state.labelList);
    if (JSON.stringify(this.state.labelList) !== "[]") {
      this.props.handleChange("labels", this.state.labelList, 3);
      // this.handleErrorMsg(this.state.labelList);
    } else {
      this.handleErrorMsg("추가한 라벨이 없습니다.");
    }
  };

  prevStep = () => {
    console.log("this.state.labelList", this.state.labelList);
    this.props.prevStep("labels", this.state.labelList, 3);
  };

  handleInputChange = e => {
    const val = e.target.value;
    this.setState({
      [e.target.name]: val
    });
  };

  handleLabelDelete = item => {
    this.setState({
      labelList: this.state.labelList.filter(d => d.display !== item)
    });
  };

  render() {
    const { vertical, horizontal, openErrorMsg } = this.state;
    return (
      <form className="form form--horizontal row__form">
        <Col>
          <Row className="form form__form-group">
            <h3 className="wizard__title">라벨을 추가해 주세요</h3>
          </Row>
          <Row>
            <Col xl={11} lg={11} md={11} sm={11} xs={11}>
              {this.state.labelList && <LabelList items={this.state.labelList} onDelete={this.handleLabelDelete} />}
            </Col>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
              <AddCircleIcon color="#643ed9" onClick={this.toggle} />
              <Modal isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader
                  style={{
                    borderRadius: "3px 3px 0 0",
                    backgroundColor: "#af82da",
                    color: "white"
                  }}
                >
                  <h4 style={{ paddingLeft: "175px" }}>Motion Add</h4>
                </ModalHeader>
                <ModalBody style={{ paddingLeft: 170 }}>
                  <Row>
                    <TextField
                      label="Motion Name"
                      margin="normal"
                      variant="outlined"
                      name="display"
                      type="text"
                      value={this.state.display}
                      onChange={this.handleInputChange}
                    />
                    {/* <TextField
                      label="label"
                      margin="normal"
                      variant="outlined"
                      name="label"
                      type="text"
                      value={this.state.label}
                      onChange={this.handleInputChange}
                      helperText="giyeok"
                    /> */}
                    {/* <Col md={1} /> */}
                  </Row>
                </ModalBody>
                <ModalFooter style={{ paddingBottom: "0px" }}>
                  <Button color="purple" outline size="sm" onClick={this.labelAdd}>
                    Add
                  </Button>{" "}
                  <Button color="purple" outline size="sm" onClick={this.toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <ButtonToolbar className="form__button-toolbar wizard__toolbar">
              <Button color="primary" style={{ size: 25, marginRight: 23 }} variant="outlined" onClick={this.prevStep}>
                Prev
              </Button>{" "}
              <Button color="primary" style={{ size: 25 }} variant="outlined" onClick={this.finishStep}>
                {/* <Link to="/projects">완료</Link> */}
                완료
              </Button>{" "}
            </ButtonToolbar>
          </Row>
        </Col>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openErrorMsg}
          onClose={this.handleSnackbarClose}
          autoHideDuration={2000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.errorMsg}</span>}
        />
      </form>
    );
  }
}

class LabelList extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired
  };

  handleLabelDelete = item => {
    this.props.onDelete(item);
  };

  render() {
    const itemList = this.props.items.map(item => (
      <Card
        style={{
          margin: 7,
          backgroundColor: "#643ed9",
          opacity: 1
        }}
      >
        <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Row>
            <Typography variant="h6" style={{ color: "#fff", marginLeft: 9 }}>
              {item.display}
            </Typography>
            <Clear style={{ paddingTop: 7 }} onClick={() => this.handleLabelDelete(item.display)} />
          </Row>
        </CardContent>
      </Card>
    ));
    return <Row>{itemList}</Row>;
  }
}

export default FormThree;
