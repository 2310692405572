/* eslint-disable react/button-has-type */
/* eslint-disable lines-between-class-members */
/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { AccountCircle } from "@material-ui/icons";

class TopbarProfile extends Component {
  static propTypes = { name: PropTypes.string.isRequired };
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const { name } = this.props;
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar">
          <AccountCircle style={{ color: "#fff" }} />
          <p className="topbar__avatar-name">{name}</p>
        </button>
      </div>
    );
  }
}

export default TopbarProfile;
