/* eslint-disable function-paren-newline */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import App from "./containers/App/App";
import AccountStore from "./stores/account";

const account = new AccountStore();

ReactDOM.render(
  <Provider account={account}>
    <App />
  </Provider>,
  document.getElementById("root")
);
