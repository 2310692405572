/* eslint-disable react/button-has-type */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-console */
import React, { Component } from "react";
import { Button, Snackbar } from "@material-ui/core";
import { AccountBox, VpnKey } from "@material-ui/icons";
// import { AccountBox, VpnKey, Visibility } from "@material-ui/icons";
import { withRouter, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import axios from "axios";
import globals from "../../App/globals";

@inject(stores => ({
  isLogin: stores.account.isLogin,
  setLogin: stores.account.setLogin,
  setEmail: stores.account.setEmail,
  setName: stores.account.setName,
  setToken: stores.account.setToken
}))
@observer
class LogInForm extends Component {
  static propTypes = {
    isLogin: PropTypes.bool.isRequired,
    setLogin: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setToken: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      navToSignin: false,
      showPassword: false,
      email: "",
      password: "",
      vertical: "top",
      horizontal: "center",
      errorMsg: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSignin = this.onSignin.bind(this);
  }

  handleErrorMsg = msg => {
    this.setState({ openErrorMsg: true, errorMsg: msg });
  };

  // 로그인 버튼 누르거나 엔터 입력시
  onSubmit = () => {
    const login = `${globals.BASE_URL}/users/login`;

    if (this.state.email === undefined || this.state.email === "") {
      this.handleErrorMsg("이메일이 없습니다. 이메일을 입력해 주세요");
      return;
    }

    if (this.state.password === undefined || this.state.password === "") {
      this.handleErrorMsg("암호가 없습니다. 암호를 입력해 주세요");
      return;
    }

    axios({
      method: "post",
      url: login,
      data: {
        email: this.state.email,
        password: this.state.password
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          if (response.data.token && response.data.token !== "") {
            // 로그인 성공
            this.props.setToken(response.data.token);
            this.props.setLogin(true);
            this.props.setEmail(response.data.email);
            this.props.setName(response.data.name);
            this.setCache("token", response.data.token);
            this.setCache("name", response.data.name);
          }
        } else {
          this.handleErrorMsg(`${response.data.msg}`);
        }
      })
      .catch(err => {
        this.handleErrorMsg(`${err}`);
      });
  };

  // 회원 가입 버튼 누름
  onSignin = () => {
    this.setState({ navToSignin: true });
  };

  // 로컬 캐시에 저장
  setCache = (key, token) => {
    localStorage.setItem(key, token);
  };

  // 로컬 캐시 읽기
  getCache = key => localStorage.getItem(key);

  // input 필드에 값을 입력 받을때 값 저장, email, password
  handleInputChange = e => {
    const val = e.target.value;
    this.setState({
      [e.target.name]: val
    });
  };

  // 엔터키 칠 때 로그인 하기
  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  showPassword = e => {
    e.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { isLogin } = this.props;
    const { vertical, horizontal, openErrorMsg } = this.state;
    if (this.state.navToSignin) return <Redirect to="/signin" />;

    const tokenStr = this.getCache("token");
    if (tokenStr !== null && tokenStr !== undefined && tokenStr !== "") {
      const decoded = jwt.decode(tokenStr);
      const date1 = Math.floor(Date.now() / 1000);

      if (decoded.exp > date1) {
        this.props.setToken(tokenStr);
        this.props.setLogin(true);
        this.props.setEmail(decoded.email);
        this.props.setName(decoded.name);
        this.setCache("name", decoded.name);
        this.setCache("email", decoded.email);
        console.log(decoded.email, decoded.name);
        return <Redirect to="/projects" />;
      }
    }

    if (isLogin) {
      return <Redirect to="/projects" />;
    }
    return (
      <div className="form">
        <div className="form__form-group">
          <span className="form__form-group-label">EMail</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountBox style={{ color: "#fff" }} />
            </div>
            <input
              name="email"
              type="text"
              placeholder="email"
              value={this.state.email}
              onChange={this.handleInputChange}
              onKeyPress={this.handleKeyPress}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <VpnKey style={{ color: "#fff" }} />
            </div>
            <input
              name="password"
              type={this.state.showPassword ? "text" : "password"}
              placeholder="password"
              value={this.state.password}
              onChange={this.handleInputChange}
              onKeyPress={this.handleKeyPress}
            />
            {/* <button
              className={`form__form-group-button${this.state.showPassword ? " active" : ""}`}
              onClick={e => this.showPassword(e)}
            >
              <Visibility style={{ color: "#fff" }} />
            </button> */}
          </div>
        </div>
        <Button
          color="primary"
          className="btn btn-primary account__btn account__btn--small"
          style={{ width: "100%", height: 40, marginTop: 20, size: 30 }}
          variant="outlined"
          onClick={this.onSubmit}
        >
          LogIn
        </Button>
        <Button
          color="primary"
          className="btn btn-default account__btn account__btn--small"
          style={{ color: "#fff", backgroundColor: "#aaa", width: "100%", height: 40, marginTop: 20, size: 30 }}
          variant="outlined"
          onClick={this.onSignin}
        >
          Sign Up
        </Button>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openErrorMsg}
          onClose={() => {
            this.setState({ openErrorMsg: false });
          }}
          autoHideDuration={2000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.errorMsg}</span>}
        />
      </div>
    );
  }
}

export default withRouter(LogInForm);
