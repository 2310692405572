/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from "react-hot-loader";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../../scss/app.scss";
import Router from "./Router";
import ScrollToTop from "./ScrollToTop";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div>
            <Router />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default hot(module)(App);
