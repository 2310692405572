/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Card, CardBody } from "reactstrap";
import Plot from "react-plotly.js";

class Chart3D extends Component {
  static propTypes = {
    jsonData: PropTypes.string.isRequired
  };

  render() {
    const { jsonData } = this.props;

    // console.log("jsonData", jsonData);

    const xArray = [];
    const yArray = [];
    const zArray = [];
    for (let i = 0; i < jsonData.length; i++) {
      xArray.push(jsonData[i].x);
      yArray.push(jsonData[i].y);
      zArray.push(jsonData[i].z);
    }

    return (
      <Col>
        <Card>
          <CardBody>
            <h3 style={{ marginBottom: 10 }}>3D Graph</h3>
            {jsonData && (
              <Plot
                data={[
                  {
                    type: "scatter3d",
                    mode: "lines",
                    opacity: 1,
                    line: {
                      color: "rgb(255, 98, 157)",
                      width: 10
                      // reversescale: true
                      // smoothing: 1.3,
                      // shape: "spline"
                    },
                    x: xArray,
                    y: yArray,
                    z: zArray
                  }
                ]}
                layout={{
                  width: 270,
                  height: 270,
                  // title: "3D",
                  //   scene: { camera: { eye: { x: 1.0, y: -1.88, z: 0.4 } } },
                  margin: {
                    t: 0, // top margin
                    l: 0, // left margin
                    r: 0, // right margin
                    b: 0 // bottom margin
                  }
                }}
                config={{
                  displayModeBar: false
                }}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default Chart3D;
