/* eslint-disable */
import React, { Component } from "react";
import { Card, CardBody, Col, Row, Badge } from "reactstrap";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@material-ui/core";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import globals from "../../../App/globals";

const ITEM_HEIGHT = 48;

@inject(stores => ({
  InProjectId: stores.account.InProjectId,
  addSelected: stores.account.addSelected,
  token: stores.account.token,
  actions: stores.account.actions,
  selected: stores.account.selected,
  setFileName: stores.account.setFileName,
  trainingList: stores.account.trainingList
}))
@observer
class TestImage extends Component {
  static propTypes = {
    InProjectId: PropTypes.number.isRequired,
    addSelected: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    token: PropTypes.string.token,
    actions: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    setFileName: PropTypes.func.isRequired,
    trainingList: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      fileName: "Basic_SDK"
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (event, option) => {
    if (option === "backdropClick") {
      this.setState({ anchorEl: null });
    } else {
      this.setState({ anchorEl: null, fileName: option });
      this.props.setFileName(option);
      this.props.addSelected([]);
    }
  };

  handleInputChange = e => {
    const val = e.target.value;
    this.setState({
      [e.target.name]: val
    });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    let actionList = "";

    let value = [];
    for (let i = 0; i < this.props.actions.length; i++) {
      for (let j = 0; j < this.props.selected.length; j++) {
        if (this.props.actions[i].actionId === this.props.selected[j]) {
          value.push({
            listId: this.props.actions[i].listId,
            jpgFile: this.props.actions[i].jpgFile,
            actionTest: this.props.actions[i].actionTest
          });
        }
      }
    }

    return (
      <Card>
        <CardBody>
          {/* // 윗줄 */}
          <Row>
            <Col>
              <h3 style={{ marginBottom: 10 }}>Motion Test</h3>
            </Col>
          </Row>
          <Row>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
              <div style={{ background: "#0054FF", marginBottom: 20 }}>
                {/* <div style={{ background: "#3A3A93", marginBottom: 20 }}> */}
                <h4
                  style={{
                    marginTop: 2,
                    paddingTop: 3,
                    paddingBottom: 3,
                    paddingLeft: 17,
                    paddingRight: 10
                  }}
                >
                  {this.state.fileName}
                </h4>
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
              <ArrowDropDown
                style={{ color: "#fff" }}
                // aria-label="More"
                aria-owns={open ? "long-menu" : undefined}
                // aria-haspopup="true"
                onClick={this.handleClick}
              />
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: 200
                  }
                }}
              >
                {this.props.trainingList.map(option => (
                  <Col>
                    <MenuItem
                      // key={option}
                      // selected={option === "Pyxis"}
                      onClick={event => this.handleClose(event, option)}
                    >
                      {option}
                    </MenuItem>
                  </Col>
                ))}
              </Menu>
            </Col>
          </Row>
          <Scrollbars style={{ height: 530 }}>
            {JSON.stringify(value) !== "[]" && (
              <Table className="material-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="material-table__cell2" padding="none">
                      Num
                    </TableCell>
                    <TableCell className="material-table__cell2" padding="none">
                      2D
                    </TableCell>
                    <TableCell className="material-table__cell2" padding="none">
                      Motion
                    </TableCell>
                    <TableCell className="material-table__cell2" padding="none">
                      Probability
                    </TableCell>
                    <TableCell className="material-table__cell2" padding="none">
                      ElapsedTime
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {value.map(d => {
                    const imgHead = `https://enkino.xyz/motion/${this.props.InProjectId}/`;
                    const imgTail = d.jpgFile;
                    const imgUrl = imgHead.concat(imgTail);

                    if (JSON.parse(d.actionTest) !== null) {
                      actionList = JSON.parse(d.actionTest)[0].resultList;
                      const label = actionList.map(data => {
                        return <p>{data.label}</p>;
                      });
                      const probability = actionList.map(data => {
                        return <p>{data.probability}</p>;
                      });
                      const elapsedTime = JSON.parse(d.actionTest)[0].elapsedTime;

                      return (
                        <TableRow className="material-table__row" key={d.listId}>
                          <TableCell className="material-table__cell2" padding="none">
                            {d.listId}
                          </TableCell>
                          <TableCell className="material-table__cell2" padding="none" component="th" scope="row">
                            <img src={imgUrl} style={{ maxWidth: 100, maxHeight: 100 }} alt="" />
                          </TableCell>
                          <TableCell className="material-table__cell2" padding="none">
                            {label}
                            {/* {JSON.parse(d.actionTest) && JSON.parse(d.actionTest)[0].label} */}
                          </TableCell>
                          <TableCell className="material-table__cell2" padding="none">
                            {probability}
                            {/* {JSON.parse(d.actionTest) && JSON.parse(d.actionTest)[0].probability} */}
                          </TableCell>
                          <TableCell className="material-table__cell2" padding="none">
                            {elapsedTime}
                            {/* {JSON.parse(d.actionTest) && JSON.parse(d.actionTest)[0].elapsedTime} */}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            )}
          </Scrollbars>
        </CardBody>
      </Card>
    );
  }
}

export default TestImage;
