import { Link } from "react-router-dom";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import Badge from "@material-ui/core/Badge";
import Notifications from "@material-ui/icons/Notifications";
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarProfile from "./TopbarProfile";

@inject(stores => ({
  isLogin: stores.account.isLogin,
  name: stores.account.name,
  inviteProjectCount: stores.account.inviteProjectCount
}))
@observer
class Topbar extends Component {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    isLogin: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    inviteProjectCount: PropTypes.number.isRequired
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility, isLogin } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className="topbar__logo" to="/" />
          </div>
          <div className="topbar__right_notice">
            <Badge badgeContent={this.props.inviteProjectCount} color="primary">
              <Link to="/invite">
                <Notifications style={{ color: "#fff" }} />
              </Link>
            </Badge>
          </div>
          <div className="topbar__right">{isLogin && <TopbarProfile name={this.props.name} />}</div>
        </div>
      </div>
    );
  }
}

export default Topbar;
