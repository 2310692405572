/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { DeleteOutline, Gesture, NoteAdd } from "@material-ui/icons";
// import { DeleteOutline, PlaylistAddCheck,  Gesture } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@material-ui/core";

class ActionTableToolbar extends Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    handleDeleteSelected: PropTypes.func.isRequired,
    handleTestSelected: PropTypes.func.isRequired,
    handleAISelected: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      modalDelete: false
    };
  }

  // 삭제 확인
  handleRemove = () => {
    this.setState({ modalDelete: true });
  };

  // 삭제 버튼
  handleRemoveButton = () => {
    this.setState({ modalDelete: false });
    this.props.handleDeleteSelected();
  };

  // 삭제 취소
  handleDeleteCancel = () => {
    this.setState({ modalDelete: false });
  };

  render() {
    const { numSelected, handleTestSelected, handleAISelected } = this.props;
    return (
      <div className="material-table__toolbar-wrap">
        <Toolbar className="material-table__toolbar" style={{ marginTop: 18 }}>
          <div>
            {numSelected > 0 && <h6 className="material-table__toolbar-selected">{`${numSelected} selected`}</h6>}
          </div>
          <div>
            {numSelected > 0 ? (
              <div>
                <Tooltip title="Motion Test" placement="top">
                  <IconButton onClick={handleTestSelected} className="material-table__toolbar-button">
                    <Gesture />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Motion Training" placement="top">
                  <IconButton onClick={handleAISelected} className="material-table__toolbar-button">
                    <NoteAdd />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete" placement="top">
                  <IconButton onClick={this.handleRemove} className="material-table__toolbar-button">
                    <DeleteOutline />
                    <Modal isOpen={this.state.modalDelete} toggle={this.toggleDelete} className={this.props.className}>
                      <ModalHeader toggle={this.toggleDelete} charCode="Y">
                        액션 삭제
                      </ModalHeader>
                      <ModalBody>
                        {numSelected}
                        개의 액션을 삭제 하시겠습니까?
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" outline onClick={this.handleRemoveButton}>
                          확인
                        </Button>{" "}
                        <Button color="default" outline onClick={this.handleDeleteCancel}>
                          취소
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <Row />
            )}
          </div>
        </Toolbar>
      </div>
    );
  }
}

export default ActionTableToolbar;
