/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import { observer, inject } from "mobx-react";
import ProjectList from "./components/ProjectList";

@inject(stores => ({
  isLogin: stores.account.isLogin
}))
@observer
class ProjectPage extends Component {
  static propTypes = {
    isLogin: PropTypes.bool.isRequired
  };

  render() {
    const { isLogin } = this.props;
    if (!isLogin) return <Redirect to="/login" />;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <ProjectList />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ProjectPage;
