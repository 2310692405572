/* eslint-disable */
import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import queryString from "query-string";
// import PropTypes from "prop-types";
import axios from "axios";
import globals from "../App/globals";

class OK extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.match.params.name
    };
  }

  componentDidMount() {
    const url = this.props.location.search;
    const params = queryString.parse(url);
    const id = params.id;

    const signin = `${globals.BASE_URL}/users/signup`;
    axios({
      method: "post",
      url: signin,
      data: {
        uuid: id
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log("ok");
        } else {
        }
      })
      .catch(err => {
        this.handleErrorMsg(`${err}`);
      });
  }

  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h4 className="account__subhead subhead">인공 지능 모션 인식 플랫폼</h4>
              <p />
              <h3 className="account__title">
                <span className="account__logo-accent"> My Motion</span>
                <span className="account__logo"> 회원가입이 완료 되었습니다.</span>
              </h3>
            </div>
            <Button color="primary" style={{ marginLeft: 130 }} variant="outlined">
              <p>
                <Link to="/login">로그인</Link>
              </p>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default OK;
