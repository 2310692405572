/* eslint-disable */
import React, { Component } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import TrainingFileCard from "./TrainingFileCard";
import globals from "../../../App/globals";

@inject(stores => ({
  token: stores.account.token,
  InProjectId: stores.account.InProjectId,
  addTrainingList: stores.account.addTrainingList
}))
@observer
class TrainingFileList extends Component {
  static propTypes = {
    token: PropTypes.string.token,
    InProjectId: PropTypes.number.isRequired,
    updateSDK: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    addTrainingList: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      trainingList: [],
      updateSDK: this.props.updateSDK
    };
  }

  componentDidMount() {
    const trainingFileList = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/trainingFileList`;
    axios({
      method: "get",
      url: trainingFileList,
      headers: {
        "x-access-token": this.props.token
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log(`ok`);
          let trainingList = response.data.trainingFileList.filter(d => d.endTime !== null);
          this.setState({
            trainingList: trainingList,
            updateSDK: false
          });
          this.props.addTrainingList(trainingList.map(d => d.trainingName));
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  }

  handleDeleteTrainingFile = trainingId => {
    let { trainingList } = this.state;

    this.setState({
      trainingList: trainingList.filter(d => d.trainingId !== trainingId).map(d => d)
    });
    this.props.addTrainingList(trainingList.filter(d => d.trainingId !== trainingId).map(d => d.trainingName));
  };

  render() {
    if (this.props.updateSDK === true) {
      this.componentDidMount();
      this.props.onUpdate();
    }

    return (
      <Col>
        <Card>
          <CardBody>
            <h3 style={{ marginBottom: 20 }}>My SDK</h3>
            {this.state.trainingList.length === 0 ? (
              <p>나만의 SDK를 만들어보세요.</p>
            ) : (
              <Scrollbars style={{ height: 180 }}>
                <TrainingFileCardList items={this.state.trainingList} onDelete={this.handleDeleteTrainingFile} />
              </Scrollbars>
            )}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const TrainingFileCardList = ({ items, onDelete }) => {
  const itemList = items.map(item => <TrainingFileCard item={item} onDelete={onDelete} />);
  return <Row>{itemList}</Row>;
};
export default TrainingFileList;
