/* eslint-disable prefer-destructuring */
import React, { PureComponent } from "react";
// import { Modal, ModalBody, ModalFooter } from "reactstrap";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import { Snackbar } from "@material-ui/core";
import { Button, Snackbar } from "@material-ui/core";
import { AccountBox, VpnKey, Visibility } from "@material-ui/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import globals from "../../App/globals";

class LogInForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      name: "",
      email: "",
      password: "",
      vertical: "top",
      horizontal: "center",
      errorMsg: ""
      // mailCheckModal: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  // // mail check modal
  // handleMailCheck = () => {
  //   this.setState({ mailCheckModal: true });
  // };

  // // mail check 확인
  // handleMailCheckConfirm = () => {
  //   this.setState({ mailCheckModal: false });
  // };

  handleErrorMsg = msg => {
    this.setState({ openErrorMsg: true, errorMsg: msg });
  };

  showPassword = e => {
    e.preventDefault();
    const showPassword = this.state.showPassword;
    this.setState({ showPassword: !showPassword });
  };

  handleInputChange = e => {
    const val = e.target.value;
    this.setState({
      [e.target.name]: val
    });
  };

  onSubmit = () => {
    if (this.state.name === undefined || this.state.name === "") {
      this.handleErrorMsg("이름이 없습니다. 이름을 입력해 주세요");
      return;
    }

    if (this.state.email === undefined || this.state.email === "") {
      this.handleErrorMsg("이메일이 없습니다. 이메일을 입력해 주세요");
      return;
    }

    if (this.state.password === undefined || this.state.password === "") {
      this.handleErrorMsg("암호가 없습니다. 암호를 입력해 주세요");
      return;
    }

    const emailCheck = `${globals.BASE_URL}/users/post_email_check`;

    const signUpEmailVerify = `${globals.BASE_URL}/users/signup_mail`;

    axios({
      method: "post",
      url: emailCheck,
      data: {
        email: this.state.email
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          axios({
            method: "post",
            url: signUpEmailVerify,
            data: {
              name: this.state.name,
              email: this.state.email,
              password: this.state.password
            }
          })
            .then(resp => {
              if (resp.data.status === "ok") {
                console.log("메일을 확인해주세요1.");
                this.handleMailCheck();
              }
              // this.handleErrorMsg(`${res.data.msg}`);
            })
            .catch(err => {
              this.handleErrorMsg(`${err}`);
            });
        } else {
          this.handleErrorMsg("이미 등록된 이메일 입니다.");
        }
      })
      .catch(err => {
        this.handleErrorMsg(`${err}`);
      });
  };

  render() {
    const { vertical, horizontal, openErrorMsg } = this.state;

    return (
      <div className="form">
        <div className="form__form-group">
          <span className="form__form-group-label">Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountBox style={{ color: "#fff" }} />
            </div>
            <input
              name="name"
              type="text"
              placeholder="name"
              value={this.state.name}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">EMail</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <VpnKey style={{ color: "#fff" }} />
            </div>
            <input
              name="email"
              type="text"
              placeholder="email"
              value={this.state.email}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <Visibility style={{ color: "#fff" }} />
            </div>
            <input
              name="password"
              type={this.state.showPassword ? "text" : "password"}
              placeholder="password"
              value={this.state.password}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        {/* <Button
          color="primary"
          className="btn btn-default account__btn account__btn--small"
          style={{ color: "#fff", backgroundColor: "#aaa", width: "100%", height: 40, marginTop: 20, size: 30 }}
          variant="outlined"
          onClick={this.onSubmit}
        >
          Sign Up
        </Button> */}
        <Button
          color="primary"
          className="btn btn-default account__btn account__btn--small"
          style={{ backgroundColor: "#aaa", width: "100%", height: 40, marginTop: 20, size: 30 }}
          variant="outlined"
        >
          <Link to="/standBy" onClick={this.onSubmit} style={{ color: "#fff" }}>
            Sign Up
          </Link>
        </Button>
        {/*
        <Link to="/standBy" onClick={this.onSubmit}>
          <h4 className="bold-text">Sign Up</h4>
        </Link> */}
        {/* <Modal isOpen={this.state.mailCheckModal} toggle={this.toggleDelete}>
          <ModalBody>Check your mail</ModalBody>
          <ModalFooter>
            <Link to="/standBy" onClick={this.handleMailCheckConfirm}>
              <h4 className="bold-text">OK</h4>
            </Link>
          </ModalFooter>
        </Modal> */}
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openErrorMsg}
          onClose={() => {
            this.setState({ openErrorMsg: false });
          }}
          autoHideDuration={2000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.errorMsg}</span>}
        />
      </div>
    );
  }
}

export default LogInForm;
