import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import ExampleCard from "./components/ExampleCard";

@inject(stores => ({
  isLogin: stores.account.isLogin
}))
@observer
class SettingsPage extends Component {
  static propTypes = {
    isLogin: PropTypes.bool.isRequired
  };

  render() {
    const { isLogin } = this.props;
    if (!isLogin) return <Redirect to="/login" />;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Settings</h3>
          </Col>
        </Row>
        <Row>
          <ExampleCard />
        </Row>
      </Container>
    );
  }
}

export default SettingsPage;
