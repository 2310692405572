/* eslint-disable */
import React, { Component } from "react";
import { Card, CardBody, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "@material-ui/core/Snackbar";
import { Button } from "@material-ui/core";
import moment from "moment";
import ActionTableHead from "./ActionTableHead";
import ActionTableToolbar from "./ActionTableToolbar";
import globals from "../../../App/globals";
import Chart from "../Chart/Chart";
import Chart3D from "../Chart/Chart3D";
import TrainingFileList from "../TrainingFile/TrainingFileList";
import TestImage from "../TestImage/TestImage";

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => b[orderBy] - a[orderBy] : (a, b) => a[orderBy] - b[orderBy];
}

@inject(stores => ({
  AIData: stores.account.AIData,
  addActionList: stores.account.addActionList,
  addActionListBackUp: stores.account.addActionListBackUp,
  addSelected: stores.account.addSelected,
  InProjectId: stores.account.InProjectId,
  token: stores.account.token,
  email: stores.account.email,
  addAIData: stores.account.addAIData,
  toggleUpdateSDK: stores.account.toggleUpdateSDK,
  updateSDK: stores.account.updateSDK,
  fileName: stores.account.fileName,
  actionsBackUp: stores.account.actionsBackUp
  // addMotionProbability: stores.account.addMotionProbability
}))
@observer
class ActionTable extends Component {
  static propTypes = {
    AIData: PropTypes.array.isRequired,
    addActionList: PropTypes.func.isRequired,
    addActionListBackUp: PropTypes.func.isRequired,
    addSelected: PropTypes.func.isRequired,
    InProjectId: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    addAIData: PropTypes.func.isRequired,
    toggleUpdateSDK: PropTypes.func.isRequired,
    updateSDK: PropTypes.bool.isRequired,
    fileName: PropTypes.string.isRequired,
    actionsBackUp: PropTypes.array.isRequired
    // addMotionProbability: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "calories",
      selected: [],
      page: 0,
      rowsPerPage: 10,
      actions: [],
      selectedSend: [],
      token: this.props.token,
      selectedNum: [],
      jsonData: "",
      vertical: "top",
      horizontal: "center",
      errorMsg: "",
      modalMotionTraining: false,
      trainingNum: 0
    };
    this.handleDeleteSelected = this.handleDeleteSelected.bind(this);
    this.handleTestSelected = this.handleTestSelected.bind(this);
    this.handleMotionTest = this.handleMotionTest.bind(this);
    this.handleAISelected = this.handleAISelected.bind(this);
    this.handleMotionTrainingButton = this.handleMotionTrainingButton.bind(this);
    this.handleTrainingStart = this.handleTrainingStart.bind(this);
  }

  componentDidMount() {
    // 액션 리스트 저장
    axios
      // .get(`http://localhost:3001/users/projects/${this.props.InProjectId}/actions`, {
      .get(`${globals.BASE_URL}/users/projects/${this.props.InProjectId}/actions`, {
        headers: { "x-access-token": this.state.token }
      })
      .then(response => {
        if (response.data.status === "ok") {
          if (response.data.actionList && response.data.actionList !== "") {
            const actionList = response.data.actionList.filter(d => d.projectId === this.props.InProjectId);
            this.props.addActionList(actionList);
            this.props.addActionListBackUp(actionList);
            this.setState({ actions: actionList });
            {
              this.setState(() => ({
                selectedNum: this.state.actions.map(d => d.actionId)
              }));
            }
          }
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
    this.props.addAIData([]);
    //프로젝트 나갔다가 들어왔을때 selected 초기화
    this.props.addSelected([]);
  }

  convertTimeToDateString = timeStamp => moment(timeStamp * 1000).format("L");

  convertTimeToTimeString = timeStamp => moment(timeStamp * 1000).format("LTS");

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  // 체크 박스 전체 선택
  handleSelectAllClick = (event, checked) => {
    const { actions } = this.state;
    if (checked) {
      this.setState(() => ({
        selected: actions.map(d => d.actionId)
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  // 체크 박스 일부 선택
  handleClick = (event, actionId) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(actionId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, actionId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    this.setState({ selected: newSelected });
  };

  // 모션 테스트 버튼 클릭 시
  handleTestSelected = () => {
    let copyData = [...this.state.actions];
    const { selected } = this.state;
    this.props.addActionList(this.props.actionsBackUp);

    for (let i = 0; i < copyData.length; i++) {
      for (let j = 0; j < selected.length; j++) {
        if (copyData[i].actionId === selected[j]) {
          this.handleMotionTest(event, copyData[i].jpgFile, selected[j]);
        }
      }
    }
    // this.props.handleMotionTestSelected(this.state.actionSend);
    this.props.addSelected(selected);
    this.setState({ actions: copyData, selected: [] });
  };

  // 경로가 predict 일때!
  // handleMotionTest = (event, jpgFile, actionId) => {
  //   // 모션 테스트_기본
  //   axios
  //     .get(`https://enkino.xyz/predict?path=/var/www/enkino.xyz/html/motion/${this.props.InProjectId}/${jpgFile}`)
  //     .then(response => {
  //       const lb = response.data.label;
  //       const pb = response.data.probability;
  //       const eT = response.data.elapsedTime;

  //       this.handleMotionVisible(actionId, lb, pb, eT);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });

  //   // console.log("data", data);
  // };

  // predict _ 모션 테스트 값 저장
  // handleMotionVisible = (actionId, lb, pb, eT) => {
  //   const { actions } = this.state;
  //   const dp = [];
  //   dp.push({ label: lb, probability: pb, elapsedTime: eT });

  //   const test = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/actions/${actionId}`;
  //   axios({
  //     method: "put",
  //     url: test,
  //     data: {
  //       token: this.state.token,
  //       actionTest: JSON.stringify(dp)
  //     }
  //   })
  //     .then(response => {
  //       if (response.data.status === "ok") {
  //         console.log("ok");

  //         for (let i = 0; i < actions.length; i++) {
  //           let actionCopy = actions;
  //           if (actionId === actionCopy[i].actionId) {
  //             actionCopy[i].actionTest = JSON.stringify([{ label: lb, probability: pb, elapsedTime: eT }]);
  //             this.setState({ actions: actionCopy });
  //             this.props.addActionList(actionCopy);
  //             break;
  //           }
  //         }
  //       } else {
  //         console.log(`error : ${response.data.msg}`);
  //       }
  //     })
  //     .catch(err => {
  //       console.log(`error:  ${err}`);
  //     });
  // };

  // // 경로가 predict3 일때!
  handleMotionTest = (event, jpgFile, actionId) => {
    // console.log("this.props.fileName", this.props.fileName);
    if (this.props.fileName === "" || this.props.fileName === "Basic_SDK") {
      // console.log("기본 테스트");
      // 모션 테스트_기본
      axios
        .get(`https://enkino.xyz/predict3?path=/var/www/enkino.xyz/html/motion/${this.props.InProjectId}/${jpgFile}`)
        .then(response => {
          const resultList = response.data.resultList;
          const elapsedTime = response.data.elapsedTime;

          // console.log("resultList", resultList);

          this.handleMotionVisible(actionId, resultList, elapsedTime);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      console.log("사용자 테스트");
      // const testImage = `http://localhost:3001/users/projects/${this.props.InProjectId}/predict_file`;
      const testImage = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/predict_file`;
      axios({
        method: "post",
        url: testImage,
        data: {
          token: this.state.token,
          trainingName: this.props.fileName,
          jpgFile: jpgFile
        }
      })
        .then(response => {
          if (response.data.status === "ok") {
            console.log("ok");
            let resultListResponse = response.data.predictFileList;
            let resultListSet = JSON.parse(resultListResponse[0].substring(2, resultListResponse[0].length - 1));
            let resultList = resultListSet.resultList;
            let resultList2 = [];
            const elapsedTime = resultListSet.elapsedTime;
            let labels = response.data.labels;

            for (let i = 0; i < resultList.length; i++) {
              for (let j = 0; j < labels.length; j++) {
                if (resultList[i].label === labels[j].label) {
                  resultList2.push({
                    label: labels[j].displayName,
                    probability: resultList[i].probability
                  });
                }
              }
            }
            this.handleMotionVisible(actionId, resultList2, elapsedTime);
          } else {
            console.log(`error : ${response.data.msg}`);
          }
        })
        .catch(err => {
          console.log(`error:  ${err}`);
        });
    }
  };

  // 모션 테스트 값 저장
  handleMotionVisible = (actionId, resultList, elapsedTime) => {
    const { actions } = this.state;
    const dp = [];
    dp.push({ resultList: resultList, elapsedTime: elapsedTime });

    for (let i = 0; i < actions.length; i++) {
      let actionCopy = actions;
      if (actionId === actionCopy[i].actionId) {
        actionCopy[i].actionTest = JSON.stringify([{ resultList: resultList, elapsedTime: elapsedTime }]);
        this.setState({ actions: actionCopy });
        this.props.addActionList(actionCopy);
        break;
      }
    }

    // const test = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/actions/${actionId}`;
    // axios({
    //   method: "put",
    //   url: test,
    //   data: {
    //     token: this.state.token,
    //     actionTest: JSON.stringify(dp)
    //   }
    // })
    //   .then(response => {
    //     if (response.data.status === "ok") {
    //       console.log("ok");

    //       for (let i = 0; i < actions.length; i++) {
    //         let actionCopy = actions;
    //         if (actionId === actionCopy[i].actionId) {
    //           actionCopy[i].actionTest = JSON.stringify([{ resultList: resultList, elapsedTime: elapsedTime }]);
    //           this.setState({ actions: actionCopy });
    //           this.props.addActionList(actionCopy);
    //           break;
    //         }
    //       }
    //     } else {
    //       console.log(`error : ${response.data.msg}`);
    //     }
    //   })
    //   .catch(err => {
    //     console.log(`error:  ${err}`);
    //   });
  };

  // 행 선택시 차트 보이기
  handleRowClick = (event, jsonFile) => {
    axios
      .get(`https://enkino.xyz/motion/${this.props.InProjectId}/${jsonFile}`)
      .then(response => {
        const data = [];
        const x1 = response.data.x;
        const y1 = response.data.y;
        const z1 = response.data.z;
        for (let i = 0; i < response.data.x.length; i++) {
          data.push({ name: i, x: x1[i], y: y1[i], z: z1[i] });
        }

        this.setState({ jsonData: data });
      })
      .catch(error => {
        console.log(error);
      });
  };

  // 리스트, 페이지 이동
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  // snackbar 사용
  handleSnackbarClose = () => {
    this.setState({ openErrorMsg: false });
  };

  handleErrorMsg = msg => {
    this.setState({ openErrorMsg: true, errorMsg: msg });
  };

  // 데이터 삭제
  handleDeleteSelected = () => {
    let copyData = this.state.actions;
    const { selected } = this.state;

    let emailCheck = false;

    for (let i = 0; i < selected.length; i++) {
      let copyEmail = copyData.filter(d => d.actionId === selected[i]).map(d => d.email);

      if (copyEmail[0] === this.props.email) {
        emailCheck = true;
      } else {
        emailCheck = false;
        break;
      }
    }

    if (emailCheck === true) {
      for (let i = 0; i < selected.length; i += 1) {
        for (let j = 0; j < copyData.length; j += 1) {
          if (copyData[j].actionId === selected[i]) {
            copyData = copyData.filter(obj => obj.actionId !== selected[i]);
          }
        }
      }
    }

    // const projectDelete = `http://localhost:3001/users/projects/${this.props.InProjectId}/actions`;
    const projectDelete = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/actions`;
    {
      emailCheck === true
        ? axios({
            method: "delete",
            url: projectDelete,
            data: {
              token: this.state.token,
              actionId: selected
            }
          })
            .then(response => {
              if (response.data.status === "ok") {
                console.log("ok");
                this.setState({ actions: copyData });
              } else {
                console.log(`error : ${response.data.msg}`);
              }
            })
            .catch(err => {
              console.log(`error:  ${err}`);
            })
        : this.handleErrorMsg("다른 멤버의 데이터는 삭제할 수 없습니다.");
    }
    this.setState({ actions: copyData, selected: [], jsonData: "" });
  };

  //모션 학습 할 데이터 저장
  handleAISelected = () => {
    let copyData = [...this.state.actions];
    const { selected } = this.state;
    const numSelected = selected.length;

    let AIDataPush = [];

    for (let i = 0; i < copyData.length; i++) {
      for (let j = 0; j < selected.length; j++) {
        if (copyData[i].actionId === selected[j]) {
          //일단 저장
          AIDataPush.push({
            jpgFile: copyData[i].jpgFile,
            displayName: copyData[i].displayName,
            label: copyData[i].label
          });
        }
      }
    }
    // 이후 1) 데이터 보내면서 2) AI페이지로 이동 또는 표시를 해야됨.
    // console.log("AIData", AIDataPush);

    // 우선 1번 데이터 보내기  Mobx에 저장
    this.props.addAIData(AIDataPush);
    this.setState({
      AIData: AIDataPush,
      selected: [],
      modalMotionTraining: true,
      trainingNum: numSelected
    });
  };

  // 모션 학습 모달 버튼
  handleMotionTrainingButton = () => {
    this.handleTrainingStart();
    this.setState({ modalMotionTraining: false });
  };

  // 모션 학습 취소
  handleMotionTrainingCancel = () => {
    this.setState({ modalMotionTraining: false });
  };

  //training 마지막 id 값 얻기
  handleTrainingStart = () => {
    const training_id = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/training_id`;
    axios({
      method: "get",
      url: training_id,
      headers: {
        "x-access-token": this.props.token
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log(`ok`);
          this.setState({
            trainingId: response.data.trainingId
          });
          this.handleTrainingId();
          // this.handleTrainingImageResize();
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  };

  removeDuplicatesArray = arr => {
    var tempArr = [];
    for (var i = 0; i < arr.length; i++) {
      if (tempArr.length == 0) {
        tempArr.push(arr[i]);
      } else {
        var duplicatesFlag = true;
        for (var j = 0; j < tempArr.length; j++) {
          if (tempArr[j].label == arr[i].label) {
            duplicatesFlag = false;
            break;
          }
        }
        if (duplicatesFlag) {
          tempArr.push(arr[i]);
        }
      }
    }
    return tempArr;
  };

  //training step 0 : trainingId 생성
  handleTrainingId = () => {
    console.log("handleTrainingId 들어옴!");
    // 라벨 종류 저장하기
    const { AIData } = this.props;
    let labels = [];
    for (let i = 0; i < AIData.length; i++) {
      labels.push({ displayName: AIData[i].displayName, label: AIData[i].label });
    }
    const labelRemoveDuplication = this.removeDuplicatesArray(labels);

    const training = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/trainingId`;
    axios({
      method: "post",
      url: training,
      data: {
        token: this.props.token,
        labels: labelRemoveDuplication
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log("ok");
          console.log("response.data.trainingId", response.data.trainingId);
          this.handleTrainingImageResize(response.data.trainingName, response.data.trainingId);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  };

  //training step 1 : 학습폴더 생성 / 이미지 복사 / size 별 폴더 생성 / size 별 폴더 아래로 저장
  handleTrainingImageResize = (trainingName, trainingId) => {
    const training = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/training`;
    axios({
      method: "post",
      url: training,
      data: {
        token: this.props.token,
        trainingId: trainingId,
        AIData: this.props.AIData
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          this.handleTrainingFinal(trainingName, trainingId);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  };

  //training  Image 학습파일 생성
  handleTrainingFinal = (trainingName, trainingId) => {
    const training_final = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/training_final`;

    axios({
      method: "post",
      url: training_final,
      data: {
        token: this.props.token,
        trainingId: trainingId,
        trainingName: trainingName
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log(`training_add_ok`);
          this.props.addAIData([]);
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
        // this.props.addAIData([]);
      });
  };

  handleAIDataDelete = () => {
    this.props.addAIData([]);
  };

  handleSDKUpdate = () => {
    this.props.toggleUpdateSDK();
  };

  isSelected = actionId => this.state.selected.indexOf(actionId) !== -1;

  render() {
    const {
      actions,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      selectedNum,
      vertical,
      horizontal,
      openErrorMsg
    } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, actions.length - page * rowsPerPage);

    return (
      <Row>
        <Col xl={8} lg={8} md={8} sm={12} xs={12}>
          <Card>
            {actions.length !== 0 ? (
              <CardBody>
                <h3>Motion List</h3>
                <ActionTableToolbar
                  numSelected={selected.length}
                  handleDeleteSelected={this.handleDeleteSelected}
                  handleTestSelected={this.handleTestSelected}
                  handleAISelected={this.handleAISelected}
                  onRequestSort={this.handleRequestSort}
                />
                <Modal isOpen={this.state.modalMotionTraining} className={this.props.className}>
                  <ModalHeader charCode="Y">Motion Training</ModalHeader>
                  <ModalBody>{this.state.trainingNum + " selected Training?"}</ModalBody>
                  <ModalFooter>
                    <Row>
                      <Button color="primary" outline onClick={this.handleMotionTrainingButton}>
                        ok
                      </Button>{" "}
                      <Button color="default" outline onClick={this.handleMotionTrainingCancel}>
                        cancel
                      </Button>
                    </Row>
                  </ModalFooter>
                </Modal>
                <div className="material-table__wrap">
                  <Table className="material-table">
                    <ActionTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={selectedNum.length}
                    />
                    <TableBody>
                      {actions
                        .sort(getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(d => {
                          const isSelected = this.isSelected(d.actionId);
                          // image url 저장
                          const imgHead = `https://enkino.xyz/motion/${this.props.InProjectId}/`;
                          const imgTail = d.jpgFile;
                          const imgUrl = imgHead.concat(imgTail);

                          return (
                            <TableRow
                              className="material-table__row"
                              role="checkbox"
                              onClick={event => this.handleRowClick(event, d.jsonFile)}
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={d.actionId}
                              selected={isSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isSelected}
                                  onClick={event => this.handleClick(event, d.actionId)}
                                  className="material-table__checkbox"
                                />
                              </TableCell>
                              <TableCell className="material-table__cell" padding="none">
                                {d.listId}
                              </TableCell>
                              <TableCell className="material-table__cell" padding="none">
                                {/* <TableCell className="material-table__cell" component="th" scope="row" padding="none"> */}
                                <img src={imgUrl} style={{ maxWidth: 50, maxHeight: 50 }} alt="" />
                              </TableCell>
                              <TableCell className="material-table__cell" padding="none">
                                {d.displayName}
                                <p>{d.md5 && d.md5}</p>
                              </TableCell>
                              <TableCell className="material-table__cell" padding="none">
                                {d.name}
                                {<br />}
                                {d.email}
                              </TableCell>
                              <TableCell className="material-table__cell" padding="none">
                                {this.convertTimeToDateString(d.dateTime)}
                                {<br />}
                                {this.convertTimeToTimeString(d.dateTime)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  component="div"
                  className="material-table__pagination"
                  count={actions.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 15]}
                />
                <Snackbar
                  anchorOrigin={{ vertical, horizontal }}
                  open={openErrorMsg}
                  onClose={this.handleSnackbarClose}
                  autoHideDuration={2000}
                  ContentProps={{
                    "aria-describedby": "message-id"
                  }}
                  message={<span id="message-id">{this.state.errorMsg}</span>}
                />
              </CardBody>
            ) : (
              <CardBody>
                <p>data upload please!</p>
              </CardBody>
            )}
          </Card>
        </Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Row>
            <TrainingFileList updateSDK={this.props.updateSDK} onUpdate={this.handleSDKUpdate} />
          </Row>
          {this.state.jsonData && (
            <Row>
              <Chart3D jsonData={this.state.jsonData} />
            </Row>
          )}
          {/* <Row>
            <Chart jsonData={this.state.jsonData} />
          </Row> */}
        </Col>
      </Row>
    );
  }
}

export default ActionTable;
