import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";

import Wizard from "./components/Form";

@inject(stores => ({
  isLogin: stores.account.isLogin
}))
@observer
class index extends Component {
  static propTypes = {
    isLogin: PropTypes.bool.isRequired
  };

  render() {
    const { isLogin } = this.props;
    if (!isLogin) return <Redirect to="/login" />;
    return (
      <Container>
        <Row>
          <Col>
            <h3 className="page-title">프로젝트를 생성합니다</h3>
            <h3 className="page-subhead subhead">아래 순서에 따라서 만들어 주세요</h3>
          </Col>
        </Row>
        <Wizard />
      </Container>
    );
  }
}

export default index;
