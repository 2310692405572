/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { Col, Card, CardBody } from "reactstrap";

class Chart extends Component {
  static propTypes = {
    jsonData: PropTypes.string.isRequired
  };

  render() {
    return (
      <Col>
        <Card>
          <CardBody>
            <ResponsiveContainer height={50} className="dashboard__area">
              <AreaChart data={this.props.jsonData} margin={{ left: -62, bottom: -31 }}>
                <XAxis dataKey="name" tickLine={false} />
                <YAxis tickLine={false} />
                <Area name="X" type="monotone" dataKey="x" fill="#4ce1b6" stroke="#4ce1b6" fillOpacity={0.2} />
                <Area name="Y" type="monotone" dataKey="y" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.2} />
                <Area name="Z" type="monotone" dataKey="z" fill="#FB4964" stroke="#FB4964" fillOpacity={0.2} />
              </AreaChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default Chart;
