/* eslint-disable func-names */
/* eslint-disable prefer-const */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-var */
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Avatar from "@material-ui/core/Avatar";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { Row, Col } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import globals from "../../App/globals";

@inject(stores => ({
  token: stores.account.token
}))
@observer
class MemberDialog extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    // selectedValue: PropTypes.string.isRequired,
    members: PropTypes.array.isRequired,
    InProjectId: PropTypes.number.isRequired,
    onMemberAdd: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      memberEmail: "",
      memberCheckResult: ""
    };
    this.handleMemberCheck = this.handleMemberCheck.bind(this);
    this.handleInviteCheck = this.handleInviteCheck.bind(this);
    this.handleInvite = this.handleInvite.bind(this);
    this.handleSignInMail = this.handleSignInMail.bind(this);
  }

  handleClose = () => {
    // this.props.onClose(this.props.selectedValue);
    this.props.onClose();
    this.setState({ open: false });
  };

  handleListItemClick = () => {
    // this.props.onClose();
    this.setState({ open: true });
  };

  handleDialogClose = () => {
    this.setState({ open: false });
  };

  handleInputChange = e => {
    const val = e.target.value;
    this.setState({
      [e.target.name]: val,
      memberCheckResult: ""
    });
  };

  handleInviteCheck = () => {
    // 프로젝트에 중복되는 회원인가 체크
    console.log("this.props.token", this.props.token);
    console.log("this.state.memberEmail", this.state.memberEmail);
    const memberCheck = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/memberCheck`;
    axios({
      method: "post",
      url: memberCheck,
      data: {
        token: this.props.token,
        email: this.state.memberEmail
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log("memberAdd _ ok");
          this.handleMemberCheck();
        } else {
          this.setState({ memberEmail: "", memberCheckResult: "이미 초대된 회원입니다." });
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
    // this.props.onMemberAdd(members);
  };

  handleMemberCheck = () => {
    // let members = this.props.members;
    // members.push({ email: this.state.memberEmail});
    // const memberSave = JSON.stringify(members);
    // const memberAdd = `http://localhost:3001/users/projects/${this.props.InProjectId}/memberAdd`;

    // 회원인가 체크
    const memberAdd = `${globals.BASE_URL}/users/post_email_check`;
    axios({
      method: "post",
      url: memberAdd,
      data: {
        token: this.props.token,
        email: this.state.memberEmail
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log("회원가입해야함.");
          // 초대 되었다고 가입하라는 메일이 가야함.
          this.handleSignInMail();
          this.handleInvite();
        } else {
          // 초대
          this.handleInvite();
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  };

  handleInvite = () => {
    // invite
    const invite = `${globals.BASE_URL}/users/projects/${this.props.InProjectId}/invite`;
    axios({
      method: "put",
      url: invite,
      data: {
        token: this.props.token,
        email: this.state.memberEmail
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log("memberAdd _ ok");
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
    this.setState({ open: false, memberEmail: "" });
  };

  handleSignInMail = () => {
    const inviteSignupMail = `${globals.BASE_URL}/users/invite_signup_mail`;
    // const inviteSignupMail = `http://localhost:3001/users/invite_signup_mail`;
    axios({
      method: "post",
      url: inviteSignupMail,
      data: {
        token: this.props.token,
        email: this.state.memberEmail
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log("mail _ ok");
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  };

  render() {
    const { classes, onClose, ...other } = this.props;
    const emails = this.props.members.map(d => d.email);

    return (
      <Row>
        <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
          <DialogTitle id="simple-dialog-title">Member List</DialogTitle>
          <div>
            <List>
              {emails
                && emails.map(email => (
                  <ListItem>
                    {/* <ListItem button onClick={() => this.handleListItemClick(email)} key={email}> */}
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={email} />
                  </ListItem>
                ))}
              <ListItem button onClick={this.handleListItemClick}>
                <ListItemAvatar>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="add Member" />
              </ListItem>
            </List>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              // PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
            >
              <DialogTitle id="draggable-dialog-title">Member Add</DialogTitle>
              <DialogContent>
                <Row>
                  <Col>
                    <TextField
                      id="outlined-with-placeholder"
                      label="Member Email"
                      margin="normal"
                      variant="outlined"
                      name="memberEmail"
                      type="text"
                      value={this.state.memberEmail}
                      onChange={this.handleInputChange}
                    />
                    <p>{this.state.memberCheckResult}</p>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleInviteCheck} color="primary">
                  Add
                </Button>
                <Button onClick={this.handleDialogClose} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Dialog>
      </Row>
    );
  }
}

export default MemberDialog;
