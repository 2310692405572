/* eslint-disable */
import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

class standBy extends Component {
  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h4 className="account__subhead subhead">Artificial intelligence motion recognition platform</h4>
              <p />
              <h3 className="account__title">
                <span className="account__logo-accent"> My Motion</span>
                <span className="account__logo">Please check your mail.</span>
              </h3>
            </div>
            <Button color="primary" style={{ marginLeft: 130 }} variant="outlined">
              <p>
                <Link to="/login">확인</Link>
              </p>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default standBy;
