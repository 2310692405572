/* eslint-disable  */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { Col, Container, Row } from "reactstrap";
import { observer, inject } from "mobx-react";
import InviteCard from "./components/ProjectInvite/InviteCard";
import globals from "../App/globals";

@inject(stores => ({
  isLogin: stores.account.isLogin,
  token: stores.account.token,
  email: stores.account.email
}))
@observer
class Invite extends Component {
  static propTypes = {
    isLogin: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      projects: "",
      inviteProjects: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  // ${globals.BASE_URL}
  // project 전체 목록
  componentDidMount() {
    axios
      .get(`${globals.BASE_URL}/users/projectAll`, {
        headers: { "x-access-token": this.props.token }
      })
      .then(response => {
        if (response.data.status === "ok") {
          if (response.data.projectList && response.data.projectList !== "") {
            // this.setState({ projects: response.data.projectList });
            const projects = response.data.projectList;
            let inviteProjects = [];
            {
              projects && console.log(" ");
              for (let i = 0; i < projects.length; i++) {
                if (projects[i].invite.indexOf(this.props.email) !== -1) {
                  inviteProjects.push({ projectId: projects[i].projectId, projectName: projects[i].projectName });
                  this.setState({
                    inviteProjects: inviteProjects
                  });
                }
              }
            }
            // this.setState({
            //   inviteProjects: inviteProjects
            // });
          }
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleChange = projectId => {
    const inviteProjects = this.state.inviteProjects.filter(d => d.projectId !== projectId);

    this.setState({
      inviteProjects: inviteProjects
    });
  };

  render() {
    const { isLogin } = this.props;
    if (!isLogin) return <Redirect to="/login" />;

    const { inviteProjects } = this.state;
    console.log(inviteProjects.length);

    return (
      <Container className="dashboard">
        <Row>
          {/* <Col md={12}> */}
          {inviteProjects.length !== 0 ? (
            <Col md={12}>
              <InviteList items={inviteProjects} onChange={this.handleChange} />
            </Col>
          ) : (
            <div style={{ textAlign: "center", margin: "0px auto", paddingTop: 50 }}>
              <h3>초대받은 프로젝트가 없습니다.</h3>
            </div>
          )}
          {/* </Col> */}
        </Row>
      </Container>
    );
  }
}

class InviteList extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = projectId => {
    this.props.onChange(projectId);
  };

  render() {
    const itemList = this.props.items.map(item => (
      <InviteCard item={item} key={item.projectId} onChange={this.handleChange} />
    ));
    return <Row>{itemList}</Row>;
  }
}
export default Invite;
