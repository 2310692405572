/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from "react";
import { Input, Row, Col } from "reactstrap";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";

class FormOne extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    projectName: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      projectName: this.props.projectName
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  nextStep = () => {
    this.props.handleChange("projectName", this.state.projectName, 1);
  };

  handleInputChange = e => {
    const val = e.target.value;
    this.setState({
      [e.target.name]: val
    });
  };

  render() {
    return (
      <form className="form form--horizontal row__form">
        <Col>
          <Row className="form form__form-group">
            <h3 className="wizard__title">프로젝트 명을 입력해 주세요</h3>
          </Row>
          <Row>
            <Col md={2} xs={2} sm={2} />
            <Col md={7} xs={7} sm={7}>
              <Input
                name="projectName"
                type="text"
                placeholder="Project Name"
                autocomplete="off"
                value={this.state.projectName}
                onChange={this.handleInputChange}
                style={{
                  color: "#fff",
                  backgroundColor: "#00000000",
                  size: 25
                }}
              />
            </Col>
            <Col md={3} xs={3} sm={3}>
              <Button color="primary" style={{ size: 25 }} variant="outlined" onClick={this.nextStep}>
                Next
              </Button>{" "}
            </Col>
          </Row>
        </Col>
      </form>
    );
  }
}

export default FormOne;
