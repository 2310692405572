import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";

import LogIn from "../LogIn/index";
import OK from "../SignInOK/index";
import standBy from "../SignInOK/standBy";
import Home from "../Home/index";
import SignIn from "../SignIn/index";
import Projects from "../Projects/index";
import ProjectCreate from "../ProjectCreate/index";
import Invite from "../Projects/Invite";
import Actions from "../Actions/index";
import Settings from "../Settings/index";

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route exact path="/" component={LogIn} />
      <Route exact path="/ok" component={OK} />
      <Route exact path="/standBy" component={standBy} />
      <Route exact path="/login" component={LogIn} />
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/projectCreate" component={ProjectCreate} />
      <Route exact path="/invite" component={Invite} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/projects/:projectId" component={Actions} />
      <Route exact path="/settings" component={Settings} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
