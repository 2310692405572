/**
 * 왼쪽 네비 메뉴
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import SidebarLink from "./SidebarLink";

@inject(stores => ({
  isLogin: stores.account.isLogin,
  setLogin: stores.account.setLogin,
  setName: stores.account.setName,
  addProjectList: stores.account.addProjectList
}))
@observer
class SidebarContent extends Component {
  static propTypes = {
    isLogin: PropTypes.bool.isRequired,
    setLogin: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    addProjectList: PropTypes.func.isRequired
  };

  setTokenCache = token => {
    localStorage.setItem("token", token);
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  login = () => {
    this.props.setLogin(false);
    this.props.setName("");
    this.setTokenCache("");
    this.props.addProjectList();
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { isLogin } = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block" style={{ display: "block" }}>
          <SidebarLink
            title={isLogin ? "Logout" : "Login"}
            icon={isLogin ? "exit" : "enter"}
            route={isLogin ? "/" : "/"}
            // route={isLogin ? "/login" : "/login"}
            onClick={this.login}
          />
        </ul>
        {isLogin && (
          <ul className="sidebar__block" style={{ display: "block" }}>
            <SidebarLink title="Home" icon="home" route="/home" onClick={this.hideSidebar} />
            <SidebarLink title="Projects" icon="list" route="/projects" onClick={this.hideSidebar} />
            {/* <SidebarLink title="Settings" icon="cog" route="/settings" onClick={this.hideSidebar} /> */}
          </ul>
        )}
      </div>
    );
  }
}

export default SidebarContent;
