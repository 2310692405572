/* eslint-disable */

import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import axios from "axios";
// import { AccountBox } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import globals from "../../../App/globals";

@inject(stores => ({
  token: stores.account.token
}))
@observer
class InviteCard extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  };

  //${globals.BASE_URL}
  handleAnswer = (event, projectId, answer) => {
    this.props.onChange(projectId);
    const inviteAnswer = `${globals.BASE_URL}/users/projects/${projectId}/inviteAnswer`;
    axios({
      method: "put",
      url: inviteAnswer,
      data: {
        token: this.props.token,
        answer: answer
      }
    })
      .then(response => {
        if (response.data.status === "ok") {
          console.log("ok");
          this.props.onChange(projectId);
        } else {
          console.log(`error : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.log(`error:  ${err}`);
      });
  };

  render() {
    const { item } = this.props;

    return (
      <Col xl={3} lg={3} md={3} sm={12} xs={12}>
        <Card>
          <CardBody>
            <Row style={{ marginBottom: 10 }}>
              <Col xl={1} lg={1} md={1} sm={12} xs={12} />
              {/* <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                <AccountBox className="member-card__white"/> 
              </Col> */}
              <Col>
                <h3 className="subhead">{item.projectName}</h3>
              </Col>
            </Row>
            <Row>
              <Col xl={1} lg={1} md={1} sm={12} xs={12} />
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={event => this.handleAnswer(event, item.projectId, "accept")}
                >
                  Accept
                </Button>
              </Col>
              <Col>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={event => this.handleAnswer(event, item.projectId, "refuse")}
                >
                  Refuse
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default InviteCard;
