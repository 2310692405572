import React, { Component } from "react";
// import GooglePlusIcon from "mdi-react/GooglePlusIcon";
// import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import LogInForm from "./components/LogInForm";

@inject(stores => ({ account: stores.account }))
@observer
class LogIn extends Component {
  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h4 className="account__subhead subhead">Artificial intelligence motion recognition platform</h4>
              {/* <h4 className="account__subhead subhead">인공 지능 모션 인식 플랫폼</h4> */}
              <p />
              <h3 className="account__title">
                <span className="account__logo">Welcome to</span>
                <span className="account__logo-accent"> My Motion</span>
                {/* <span className="account__logo"> 에 오신 것을 환영합니다</span> */}
              </h3>
            </div>
            <LogInForm />
            {/* <div className="account__or">
              <p>소셜 로그인</p>
            </div>
            <div className="account__social">
              <Link className="account__social-btn account__social-btn--google" to="/signin">
                <GooglePlusIcon />
              </Link>
            </div> */}
            <div className="account__forgot-password">{/* <a href="/">암호를 잊으셨나요?</a> */}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogIn;
