/* eslint-disable prefer-const */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-console */
import { observable, action } from "mobx";

export default class AccountStore {
  @observable
  isLogin = false;

  @observable
  email = "";

  @observable
  ownerEmail = "";

  @observable
  name = "";

  @observable
  token = "";

  @observable
  InProjectId = -1;

  @observable
  projects = [];

  @observable
  actions = [];

  // Motion Test 값 초기화
  @observable
  actionsBackUp = [];

  @observable
  AIData = [];

  @observable
  updateSDK = false;

  @observable
  projectName = "";

  @observable
  selected = [];

  @observable
  fileName = "";

  @observable
  trainingList = [];

  @observable
  inviteProjectCount = 0;

  @action
  setInviteProjectCount = inviteProjectCount => {
    this.inviteProjectCount = inviteProjectCount;
  };

  @action
  setFileName = fileName => {
    this.fileName = fileName;
  };

  @action
  addSelected = data => {
    this.selected = [];
    data.forEach(item => {
      this.selected.push(item);
    });
  };

  @action
  setProjectName = projectName => {
    this.projectName = projectName;
  };

  @action
  toggleLogin = () => {
    this.isLogin = !this.isLogin;
  };

  @action
  toggleTrainingStatus = () => {
    this.trainingStatus = !this.trainingStatus;
  };

  @action
  toggleUpdateSDK = () => {
    this.updateSDK = !this.updateSDK;
  };

  @action
  setLogin = boolValue => {
    this.isLogin = boolValue;
  };

  @action
  setName = name => {
    this.name = name;
  };

  @action
  setEmail = email => {
    this.email = email;
  };

  @action
  setOwnerEmail = ownerEmail => {
    this.ownerEmail = ownerEmail;
  };

  @action
  setToken = token => {
    this.token = token;
  };

  @action
  setProjectId = InProjectId => {
    console.log(typeof InProjectId);
    this.InProjectId = InProjectId;
  };

  @action
  addProjectList = data => {
    this.projects = [];
    data.forEach(item => {
      this.projects.push(item);
    });
  };

  @action
  addActionList = data => {
    this.actions = [];
    let dataCopy = data;

    if (data.length !== 0) {
      for (let i = 0; i < data.length; i++) {
        dataCopy[i].listId = i + 1;
      }
    }

    dataCopy.forEach(item => {
      this.actions.push(item);
    });
  };

  @action
  addActionListBackUp = data => {
    this.actionsBackUp = [];
    let dataCopy = data;

    if (data.length !== 0) {
      for (let i = 0; i < data.length; i++) {
        dataCopy[i].listId = i + 1;
      }
    }

    dataCopy.forEach(item => {
      this.actions.push(item);
    });
  };

  @action
  addAIData = data => {
    this.AIData = [];
    data.forEach(item => {
      this.AIData.push(item);
    });
  };

  @action
  removeProject = projectId => {
    this.projects = this.projects.filter(item => item.projectId !== projectId);
  };

  @action
  addTrainingList = data => {
    this.trainingList = ["Basic_SDK"];
    data.forEach(item => {
      this.trainingList.push(item);
    });
  };
}
